import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';



const VendorProfilePage = Loadable(lazy(() => import("./VendorProfilePage")));
const Business = Loadable(lazy(() => import("./child/Business")));
// const KycVerification = Loadable(lazy(() => import("./kyc/KycVerification")));
// const KycDocument = Loadable(lazy(() => import("./kyc/KycDocument")));
const Personal = Loadable(lazy(() => import("./child/Personal")));
const EditPersonaldtl = Loadable(lazy(() => import("./child/EditPersonaldtl")));
// const KycApproved = Loadable(lazy(() => import("./kyc/KycApproved")));





const profileRoutes = [
    {
        path: "/profile",
        element: <VendorProfilePage />,
        auth: authRoles.guest,
    },

    {
        path: "/personal",
        element: <Personal />,
        auth: authRoles.guest,
    },
    {
        path: "/bussiness",
        element: <Business />,
        auth: authRoles.guest,
    },
    // {
    //     path: "/vendor-kyc-verification",
    //     element: <KycVerification />,
    //     auth: authRoles.guest,
    // },
    // {
    //     path: "/vendor-kyc-document",
    //     element: <KycDocument />,
    //     auth: authRoles.guest,
    // },
    // {
    //     path: "/profile/vendor-kyc-approved",
    //     element: <KycApproved />,
    //     auth: authRoles.guest,
    // },


    {
        path: "/personal-edit/:id",
        element: <EditPersonaldtl />,
        auth: authRoles.guest,
    },

];

export default profileRoutes;