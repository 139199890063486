import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Navigate } from 'react-router-dom';

const TounamentList = Loadable(lazy(() => import('./TounamentList')));
const AddTournament = Loadable(lazy(() => import('../Admin/Tournament/AddTournament')));
const AddSponsors = Loadable(lazy(() => import('../Admin/Tournament/AddSponsors')));
const EditTournament = Loadable(lazy(() => import('../Admin/Tournament/EditTournament')));
const TournamentDetail = Loadable(lazy(() => import('../Admin/Tournament/TournamentDetail')));
const MatchDetail = Loadable(lazy(() => import('../Admin/Tournament/StartMatch/MatchDetail')));
const AddTeam = Loadable(lazy(() => import('../Admin/Tournament/Team/AddTeam')));
const ScorerList = Loadable(lazy(() => import("./Manager/Scorer/ScorerList")));
const AddScorer = Loadable(lazy(() => import("../Admin/Scorer/AddScorer")));



const ManagerRoutes = [
  {
      path: "/tournament",
      element: <TounamentList />,
      auth: authRoles.guest,
  },
  {
      path: "/tournament/add",
      element: <AddTournament />,
      auth: authRoles.guest,
  },
  {
      path: "/tournament/sponsors/:id",
      element: <AddSponsors />,
      auth: authRoles.guest,
  },
  {
      path: "/tournament/edit/:id",
      element: <EditTournament />,
      auth: authRoles.guest,
  },
  {
      path: "/tournament/tournament-detail/:id",
      element: <TournamentDetail />,
      auth: authRoles.guest,
  },
  {
      path: "/tournament/match-detail",
      element: <MatchDetail />,
      auth: authRoles.guest,
  },
  {
      path: "/tournament/add-team",
      element: <AddTeam />,
      auth: authRoles.guest,
  },
  {
    path: "/manager/scorer-list",
    element: <ScorerList />,
    },
    // {
    //     path: "/scorer/add",
    //     element: <AddScorer />,
    // },
  { path: '/*', element:  <Navigate to="/tournament"/>, auth:authRoles.guest },
]
export default ManagerRoutes;