import { createSlice } from "@reduxjs/toolkit"

export const AvailableSlotsData = createSlice({
	name: 'Data',
	initialState: {
		data: '',
	},
	reducers: {
		setAvailableData: (state, action) => {
			state.data = action.payload
		},

		// setSignupOtp: (state, action) => {
		// 	state.signupOtp = action.payload
		// },
	},

})

// Action creators are generated for each case reducer function
export const { setAvailableData } = AvailableSlotsData.actions

export default AvailableSlotsData.reducer