import { createSlice } from '@reduxjs/toolkit'
// import type { PayloadAction } from '@reduxjs/toolkit'

// export interface CounterState {
//   value: number
// }

// const initialState: CounterState = {
//   value: 0,
// }

export const VendoreLogin = createSlice({
  name: 'Login',

  initialState:{
    value : 0
  },
  reducers: {
    LoginID: (state, action) => {
      state.value = action.payload
    },
   
      
  },
})

// Action creators are generated for each case reducer function
export const { LoginID } = VendoreLogin.actions

export default VendoreLogin.reducer


