
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const ManagerLogin = Loadable(lazy(() => import('./ManagerLogin')));
const ScorerLogin = Loadable(lazy(() => import('./ScorerLogin')));
const ScorerVerifyEmail = Loadable(lazy(() => import('./ForgetPasword/ScorerVerifyEmail')));
const VerifyOTP = Loadable(lazy(() => import('./ForgetPasword/VerifyOTP')));
const ForgetPassword = Loadable(lazy(() => import('./ForgetPasword/ForgetPassword')));


const scorerLoginRoutes = [
  { path: '/manager/signin', element: <ManagerLogin /> },
  { path: '/scorer/signin', element: <ScorerLogin /> },
  { path: "/scorer/verify/email", element: <ScorerVerifyEmail />,},
    { path: '/scorer/verifyOTP', element: <VerifyOTP /> },
    { path: '/scorer/forget/password', element: <ForgetPassword /> },
];

export default scorerLoginRoutes;
