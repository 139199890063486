import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';



const AnalyticsPage = Loadable(lazy(() => import("./AnalyticsPage")))



const analyticRoutes = [
    {
        path: "/analytics",
        element: <AnalyticsPage />,
        auth: authRoles.guest,
    },

];

export default analyticRoutes;