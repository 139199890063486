import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';


const AminityList = Loadable(lazy(() => import("./AminityList")));
const EditAminity = Loadable(lazy(() => import("./EditAminity")));
const AddAminity = Loadable(lazy(() => import("./AddAminity")));




const aminityRoutes = [
    {
        path: "/amenity/amenity-list",
        element: <AminityList />,
        auth: authRoles.guest,
    },
 
    {
        path: "/amenity/edit/:id",
        element: <EditAminity/>,
        auth: authRoles.guest,
    },
    {
        path: "/amenity/add",
        element: <AddAminity />,
        auth: authRoles.guest,
    },
    

];

export default aminityRoutes;