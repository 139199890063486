import { convertObjToQueryStr, _get ,_putAuth ,_postAuth, _delete} from "api/Apimethod"
import constant from "constant"




// export const getTeams = async () =>{
// 	const response = await _get(constants.teams.leamList)
// 	return response.data
// }

export const getTeams = async (params) =>{
    let url = constant.Admin.teams.leamList
    let str = convertObjToQueryStr(params)
    url = url + str;
    const response = await _get(url)
    return response?.data;
}

export const getAllTeams = async () =>{
    let url = constant.Admin.teams.leamDetail
    const response = await _get(url)
    return response?.data;
   }

export const getTeamsDetail = async (id) =>{
    let url = constant.Admin.teams.leamDetail + id
    // let str = convertObjToQueryStr(params)
    // url = url + str;
    const response = await _get(url)
    return response?.data;
   }