import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';





const SettlementList = Loadable(lazy(() => import("./SettlementList")));




const vendorReportsRoutes = [
    {
        path: "/settlement-list",
        element: <SettlementList />,
        auth: authRoles.guest,
    },



  
];

export default vendorReportsRoutes;