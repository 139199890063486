import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios.js'
import { MatxLoading } from 'app/components'
import { _post  } from 'api/Apimethod'
import { toast } from "react-toastify";
import {  useNavigate } from 'react-router-dom'
import { VendorProfile,scorerProfile,managerProfile } from 'services/vendor/Profile';
import Cookies from 'js-cookie';

const initialState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null,
    role:null,
}

const setSession = (accessToken) => {
    if (accessToken ) {
        if(process.env.REACT_APP_NODE_ENV==='PRODUCTION'){
            Cookies.set('authToken', accessToken, { expires: 1, secure: true, sameSite: 'Strict' });
        }else{
            Cookies.set('authToken', accessToken, { expires: 1, secure: false, sameSite: 'Strict' });
        }
        axios.defaults.headers.common.Authorization = `${accessToken}`
    } else {
        Cookies.remove('authToken', { path: '/' }); 
        Cookies.remove('role', { path: '/' });
        delete axios.defaults.headers.common.Authorization
    }
}

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user,role } = action.payload

            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user,
                role,
            }
        }
        case 'LOGIN': {
            const { user,role } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
                role,
            }
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            }
        }
        case 'REGISTER': {
            const { user } = action.payload

            return {
                ...state,
                isAuthenticated: true,
                user,
            }
        }
        default: {
            return { ...state }
        }
    }
}

const AuthContext = createContext({
    ...initialState,
    method: 'JWT',
    login: () => Promise.resolve(),
    vendorlogin: () => Promise.resolve(),
    logout: () => { },
    // register: () => Promise.resolve(),
})

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const login = async (typeApiUrl,credential) => {
        try {
            // '/admin/sign-in'
            const response = await _post(typeApiUrl, {
                ...credential
                }); 
            const user = response;
            const { status } = response;
            if (status === true) {
                const role = response?.data?.role
                setSession(response?.data?.token) 
                if(process.env.REACT_APP_NODE_ENV==='PRODUCTION'){
                    Cookies.set('role', response?.data?.role, { secure: true, sameSite: 'Strict' });    
                }else{
                    Cookies.set('role', response?.data?.role, { secure: false, sameSite: 'Strict' });
                }
                toast.success(response?.message)
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user,
                        role,
                    },
                });
            } else {

                toast.error(response?.message)    
                // navigate('/admin/signin')
                return false
            }
        } catch (e) {

            toast.error(e.message);
            // navigate('/admin/signin')
            return false
        }
    };
    const vendorlogin = async (postdata) => {
        try {
            const response = await _post('/auth/verify-otp',{
                    ...postdata
                })
            const user = response;
            const { status } = response;
            if (status === true) {
                const role = response?.data?.role
                setSession(response?.data?.token) 
                if(process.env.REACT_APP_NODE_ENV==='PRODUCTION'){
                    Cookies.set('role', role, { secure: true, sameSite: 'Strict' });    
                }else{
                    Cookies.set('role', role, { secure: false, sameSite: 'Strict' });
                }
                dispatch({
                    type: 'LOGIN',
                    payload: {
                        user,
                        role,
                    },
                });
                return response
            } 
        } catch (e) {
            toast.error(e.message);
        }
    };

    // const register = async (email, username, password) => {
    //     const response = await axios.post('/api/auth/register', {
    //         email,
    //         username,
    //         password,
    //     })

    //     const { accessToken, user } = response.data

    //     setSession(accessToken)

    //     dispatch({
    //         type: 'REGISTER',
    //         payload: {
    //             user,
    //         },
    //     })
    // }

    const logout = () => {
        setSession(null)
        dispatch({ type: 'LOGOUT' })
    }

    useEffect(() => {
         (async () => {
            const accessToken = Cookies.get('authToken');
            const role = Cookies.get('role');
            let user = ''
             try {
                 if (accessToken) {
                     setSession(accessToken)
                     window.addEventListener("load", async () => {
                        let resData = {}
                        if(role === 'MANAGER'){
                            resData = await managerProfile(navigate)
                        }else if (role === 'SCORER'){
                            resData = await scorerProfile(navigate)
                        }else{
                            resData = await VendorProfile(navigate)
                        }
                        if(resData?.data){
                            let user = resData?.data
                            dispatch({
                                type: 'INIT',
                                payload: {
                                    isAuthenticated: true,
                                    user,
                                    role
                                },})
                        }else if(resData?.code === 401){
                            dispatch({
                                type: 'INIT',
                                payload: {
                                    isAuthenticated: false,
                                },
                            })
                        }else{
                            dispatch({
                                type: 'INIT',
                                payload: {
                                    isAuthenticated: true,
                                    role
                                },
                            })
                        }
                      });
                } else {
                    dispatch({
                        type: 'INIT',
                        payload: {
                            isAuthenticated: false,
                            user,
                            role
                        },
                    })
                    // navigate("/session/signin")
                }
            } catch (err) {

                console.error(err)
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user
                    },
                })
            }
        })()
    }, [])
    const navigate= useNavigate()
    if (!state.isInitialised) {
        return <MatxLoading/>
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                vendorlogin,
                logout,
                // register,    
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext
