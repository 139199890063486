import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import moment from "moment";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { SlLocationPin } from "react-icons/sl";
import { useParams } from "react-router-dom";
import { getFixtureTeamList, getMatchList, getTeamList } from "services/admin/Tournamet";
import { scoreFunctions } from "../StartMatch/scoring/scoreFunctions";
import { decryptData } from "app/helperFuntion/encryptDecrypt";

let obj = {
  LEAGUE: "League",
  PRE_QUARTERFINAL: "Pre Quater Final",
  QUARTERFINAL: "Quater Final",
  SEMI_FINAL: "Semi Final",
  FINAL: "Final",
};


const Matches = ({
  state,
  setRunningMatchData,
  setMatchValue,
  setTeamAPlayer,
  setTeamBPlayer,
  setCurMatchListData,
  setSubMatch,
}) => {
  const [matchData, setMatchData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [selectedStage, setSelectedStage] = useState("LEAGUE");
  const [teamList, setTeamList] = useState([]);
  const [selTeam, setSelTeam] = useState("");
  const [tab, setTab] = useState({
    'League':'',
    'Pre Quater Final':'',
    'Quater Final':'',
    'Semi Final':'',
    'Final':'',
  });
  let obj = {
    'LEAGUE': 'League',
    'PRE_QUARTERFINAL':'Pre Quater Final',
    'QUARTERFINAL':'Quater Final',
    'SEMI_FINAL':'Semi Final',
    'FINAL':'Final',
  }
  let newTab = Object.values(tab).filter((item) => item.length > 0)

  const handleChange = (event, newValue) => {
    getData(newValue,selTeam)
    setSelectedStage(newValue);
  };

  const getData = async (stage,teamId) => {
    let toParamsKey = Object.keys(obj)[Object.values(obj).indexOf(stage)]
    setLoading(true);
    setSelTeam(teamId)
    const params = { tournament_id: decryptData(id) };
    if(teamId) params.team_id = teamId
    if(stage) params.tournament_stages= toParamsKey
    await getMatchList(params)
      .then((res) => {
        setLoading(false);
        if (res?.status) {
          setMatchData(res.data.data);
          if(!stage && res.data.data.length>0){
            let tabObj = {...tab}
            for(let i =0;i<res.data.data.length;i++){
              let stages = res.data.data[i]?.tournament_stages
              if(stages) tabObj[obj[stages]] = obj[stages]
            }
            setTab(tabObj)
            let arr = Object.values(tabObj).filter((itm)=> itm.length>0)
            setSelectedStage(arr[arr.length-1]);
            getData(arr[arr.length-1])
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getTeamListData = async () => {
    let params = { tournament_id: decryptData(id) };
    await getTeamList(params).then((res) => {
      if (res?.status) {
        setTeamList(res?.data?.data);
      }
    });
  };

  useEffect(() => {
    getTeamListData();
  }, []);

  const getMatchRunning = async (data) => {
    await getFixtureTeamList(data._id).then((res) => {
      if (res?.status) {
        setTeamAPlayer(res.data.data[0].teamA_players[0]);
        setTeamBPlayer(res.data.data[0].teamB_players[0]);
      }
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const getApproxOvers = (overs) => {
    return (
      (String(overs).split(".")[1] === "6" &&
        Number(String(overs).split(".")[0]) + 1) ||
      overs
    );
  };

  const getMatchPosition = (curmatch) => {
    let obj = false;
    if (curmatch?.match) {
      let inning1;
      let inning2;
      if (curmatch?.match?.super_over && curmatch.match.super_over.length > 0) {
        inning1 =
          curmatch.match.super_over[curmatch.match.super_over.length - 1]
            .innings[0];
        inning2 =
          curmatch.match.super_over[curmatch.match.super_over.length - 1]
            .innings[1];
      } else {
        inning1 =
          (curmatch.match.innings[0].first_bat && curmatch.match.innings[0]) ||
          curmatch.match.innings[1];
        inning2 =
          (curmatch.match.innings[0].first_bat && curmatch.match.innings[1]) ||
          curmatch.match.innings[0];
      }
      obj = {
        inning1: { ...inning1 },
        inning2: { ...inning2 },
        outcome: scoreFunctions.getOutCome(curmatch.match),
      };
    }
    return obj;
  };

  const getCurrentMatch = (item) => {
    if (item?.match) {
      setRunningMatchData(item.match);
      setMatchValue("matches_score");
      getMatchRunning(item);
      setCurMatchListData(item);
      if (item.match?.super_over && item.match.super_over.length > 0) {
        let lastSuperOver =
          item.match.super_over[item.match.super_over.length - 1];
        setSubMatch(lastSuperOver);
      } else {
        setSubMatch(item.match);
      }
    }
  };

  return (
    <Grid container spacing={3}>
      {/* {matchData && Array.isArray(matchData) &&
        matchData.length > 0 &&  */}
          <Grid item lg={10} md={6} sm={12} xs={12}>
            <TabContext className="subtitle-text" value={selectedStage}>
              <Box  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    backgroundColor: "white",
                  }}>
                <TabList
                  onChange={handleChange}
                  TabIndicatorProps={{
                    sx: {
                      border: "1px solid black",
                    },
                  }}
                  sx={{
                    "& .MuiTab-root": {
                      fontFamily: "Inter-SemiBold",
                    },
                    ".Mui-selected": {
                      color: `#171717 !important`,
                    },
                  }}
                >
                {/* <Tab label="League" value="League" /> */}
                {newTab && newTab.length > 0 && newTab.map((item, index) => {
                  return <Tab key={index} label={item} value={item} />;
                })}
                </TabList>
              </Box>
            </TabContext>
          </Grid>
         {/* } */}


      <Grid item lg={2} md={6} sm={12} xs={12}>
        <FormControl style={{ minWidth: 180, backgroundColor:"white" }} InputLabelProps={{ shrink: true }}>
          <InputLabel id="demo-simple-select-label" className="lh-1" >Teams</InputLabel>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            style={{ height:"48px" }}
            label="Teams"
            value={selTeam}
            // label="Booking status"
            // displayEmpty
            onChange={(e) => getData(selectedStage, e.target.value)}
          >
            <MenuItem value="">All</MenuItem>
            {teamList && Array.isArray(teamList) && teamList.length>0 &&
             teamList.map((item,i)=>(
              <MenuItem value={item.team_id}>{item.team_name}</MenuItem>
             ))}
          </Select>
        </FormControl>
      </Grid>



      {(matchData && Array.isArray(matchData) &&
        matchData.length > 0 &&
        matchData.map((item, index) => {
          let curmatch = getMatchPosition(item);
          // console.log(curmatch,'curmatchcurmatch')
          return (
            <Grid item lg={4} md={6} sm={12} xs={12} key={index}>
              <div
                className="rounded p-3 pb-2 bg-white"
                style={{ boxShadow: "0px 0px 9px rgba(0, 0, 0, 0.25)" }}
                onClick={() => {
                  getCurrentMatch(item);
                }}
              >
                <div className="d-flex justify-content-between align-center">
                  <div style={{ fontSize: 13 }}>
                    <h6>
                      {(state?.tournament_name &&
                        item?.tournament_stages &&
                        `${state.tournament_name}  (${
                          obj[item.tournament_stages]
                        })`) ||
                        ""}
                    </h6>
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        fontSize: 14,
                        fontWeight: "600",
                        padding: "0px 10px",
                        borderRadius: 14,
                        backgroundColor:
                          item?.extras 
                          ? "#262626"
                          : item?.match && scoreFunctions.checkIsMatchCompleted(item.match)
                          ? "#262626"
                          : item?.match
                          ? "#A00D00"
                          : "#FFAA05",
                      }}
                    >
                      {item?.extras 
                        ? "Completed"
                        : item?.match && scoreFunctions.checkIsMatchCompleted(item.match)
                        ? "Completed"
                        : item?.match
                        ? "Live"
                        : "Upcoming"}
                    </Button>
                  </div>
                </div>
                <div style={{ fontSize: 14 }} className="mt-1">
                  <div className="text-secondary d-flex justify-content-between">
                    <span>
                      {(item?.match?.match_settings?.date &&
                        moment(
                          item.match.match_settings.date.slice(0, 16)
                        ).format("DD-MMM-YYYY, h:mm A")) ||
                        moment(item.date.slice(0, 16)).format(
                          "DD-MMM-YYYY, h:mm A"
                        )}
                    </span>
                    <span>
                      {item?.match?.match_settings?.no_of_overs ||
                        state?.match_overs}{" "}
                      Over
                    </span>
                  </div>
                  <div className="text-secondary">
                    <span>
                      <SlLocationPin className="fs-6" />
                    </span>
                    {state?.city}
                  </div>
                </div>

                <hr style={{ marginTop: 8, marginBottom: 8 }} />
                <div
                  className="d-flex justify-content-between align-items-center"
                  style={{ fontSize: 14 }}
                >
                  {(curmatch && curmatch.inning1.batting_team) ||
                    item?.teamA_name}
                  <div>
                    <span className="text-primary fs-6">
                      {(curmatch &&
                        curmatch?.inning1?.runs_scored &&
                        curmatch.inning1.runs_scored +
                          "/" +
                          curmatch?.inning1?.wickets_lost) ||
                        "Not Started"}
                    </span>
                    <span className="text-secondary">
                      {(curmatch &&
                        curmatch?.inning1?.overs_played &&
                        `(${getApproxOvers(
                          curmatch.inning1.overs_played
                        )} ov)`) ||
                        ""}
                    </span>
                  </div>
                </div>
                <div
                  className="d-flex justify-content-between align-items-center mt-2"
                  style={{ fontSize: 14 }}
                >
                  {(curmatch && curmatch.inning2.batting_team) ||
                    item?.teamB_name}
                  <div>
                    <span className="text-primary fs-6">
                      {(curmatch &&
                        curmatch?.inning2?.runs_scored &&
                        curmatch.inning2.runs_scored +
                          "/" +
                          curmatch.inning2.wickets_lost) ||
                        "Not Started"}
                    </span>
                    <span className="text-secondary">
                      {(curmatch &&
                        curmatch?.inning2?.overs_played &&
                        `(${getApproxOvers(
                          curmatch.inning2.overs_played
                        )} ov)`) ||
                        ""}
                    </span>
                  </div>
                </div>
                <hr style={{ marginTop: 8, marginBottom: 8 }} />
                <div>
                  <div className="text-center">
                    <h6 style={{ fontSize: 12 }}>
                      {item?.extras 
                      ? item.extras.result==='NORESULT' 
                      ? 'No Result'
                      : `${item.extras.team_id===item.teamA_name ? item.teamA_name : item.teamB_name} got BYE`
                      : curmatch 
                      ? curmatch?.outcome 
                      : "Not Started"}
                    </h6>
                  </div>
                </div>
              </div>
            </Grid>
          );
        })) || (
        <Grid item lg={12} md={6} sm={12} xs={12}>
          {/* <SimpleCard> */}
            <div
              className="w-100 d-flex justify-content-center align-items-center"
              style={{ height: "200px" }}
            >
              {loading ? <Spinner /> : "No Matches Found"}
            </div>
          {/* </SimpleCard> */}
        </Grid>
      )}
    </Grid>
  );
};

export default Matches;
