import moment from "moment";
import { _get } from "api/Apimethod"
import constant from "constant";
import * as XLSX from "xlsx"
const GoogleApiKey = 'AIzaSyCvN8-7UMgVmqSTJnWmkVvghKiWufdp3Qk';

let numInWords = {
  a: [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ],
  b: [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ],
};


export const Helper = {
  nextHour: (time = "", hourDiff = 1) => {
    var momentObj = moment(time, ["HH:mm"]).add(hourDiff, 'hours');
    momentObj = momentObj.format("HH:mm:ss")
    return momentObj
  },
  prevHour: (time = "", hourDiff = 1) => {
    let newTime = ''
    if(time.split(':').length >= 3){
      newTime = `${(Number(time.split(':')[0])-Number(hourDiff))}:${time.split(':')[1]}:${time.split(':')[2]}`
    }else if(time.split(':').length === 2){
      newTime = `${(Number(time.split(':')[0])-Number(hourDiff))}:${time.split(':')[1]}`
    }
    return newTime
  },

  checkDifference: (from = "", to = "") => {
    var To = (to.split(':')[1].split(' ')[1] === 'PM' && Number(to.split(':')[0]) < 12) ? Number(to.split(':')[0]) + 12 : to.split(':')[0];
    var From = (from.split(':')[1].split(' ')[1] === 'PM' && Number(from.split(':')[0]) < 12) ? Number(from.split(':')[0]) + 12 : from.split(':')[0];
    var ToMin = (Number(to.split(':')[1].split(' ')[0]) / 60) * 100;
    var FromMin = (Number(from.split(':')[1].split(' ')[0]) / 60) * 100;
    var Diff = Number(`${To}${ToMin === 0 ? '00' : ToMin}`) - Number(`${From}${FromMin === 0 ? '00' : FromMin}`)
    return Diff / 100
  },

  checkDifference24 : (from =  "", to = "") => {
    let From = Number(from.split(':')[0]) * 100 + Number(from.split(':')[1]);
    let To = Number(to.split(':')[0]) * 100 + Number(to.split(':')[1]);

    let Diff = 0
    if(To === 0 && From === 0){
      Diff = 0
    }
    else if(To === 0){
        Diff = 2400 - From
    }else{
        Diff = To - From;
    }
    return Diff / 100
  },

  dateConverter: (date = "") => {
    date = new Date(date)
    var hours = date.getUTCHours();
    var minutes = date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : date.getUTCMinutes();
    var am = hours >= 12 ? 'PM' : 'AM';
    var hour = hours % 12 || 12;
    var Hour = hour < 10 ? `0${hour}` : hour;
    return Hour + ':' + minutes + ' ' + am;
  },
  
  getPlaceList: async (val) => {
    let url = `${constant.googleApi.place}?search=${val}&city=true`
    const res = await _get(url) 
    if(res?.data?.status){
      return res.data.data.predictions
    }
  },
  getPlaceListAll: async (val) => {
    let url = `${constant.googleApi.place}?search=${val}`
    const res = await _get(url) 
    if(res?.data?.status){
      return res.data.data.predictions
    }
  },

  PlaceId: async (val,withCity) => {
    let location = {}
    let url = `${constant.googleApi.placeId}?placeId=${val}`
    const response = await _get(url)
    let data = response?.data?.data
    if(withCity){
      location = {
        city:      data?.city || "",
        state:     data?.state || "",
        country:   data?.country || "",
        latitude:  data?.location?.lat || null,
        longitude: data?.location?.lng || null,
        address: data?.address || ""
      };
    }else{
      location = {
        latitude:  data?.location?.lat || null,
        longitude: data?.location?.lng || null,
      };
    }
    return location
  },

  calcRemainingTime : (date) => {
    let newDate = new Date() - new Date(date);
    let condate = new Date(newDate);
    // console.log(condate ,"new Date")
    let parseDate = "";
    if (condate.getUTCDate() - 1 > 0) {
      parseDate = `${
        condate.getUTCDate() - 1
      }d ${condate.getUTCHours()}h ${condate.getMinutes()}m`;
    } else if (condate.getUTCHours() > 0) {
      parseDate = `${condate.getUTCHours()}h ${condate.getUTCMinutes()}m`;
    } else {
      parseDate = `${condate.getUTCMinutes()}m`;
    }
    return parseDate;
  },

  checkMinimumAxis : (arr,max) => {
    return Array.isArray(arr) && arr.find((item)=> item > (max || 30)) && Math.max(...arr) || (max || 30)
  },

  // getPlaceListApi: async (val, isCityList = false) => {
  //   var country = 'in';
  //   var list = [];
  //   var isCity = isCityList ? '&types=(cities)' : ''
  //   list = await fetch(
  //     `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${val}&components=country:${country}${isCity}&key=${GoogleApiKey}&inputtype=textquery&language=en`
  //      )
  //     .then(response => response.json())
  //     .then(res => {
  //       const data = [];
  //       res.predictions.map((val) => {
  //       return data.push(val);
  //       });
  //       return data;
  //     })
  //     .catch(err => {
  //       console.log(err);
  //     });
  //   return list;
  // },

  // PlaceIdApi: async (val) => {
  //   var location = {};
  //   await fetch(
  //     `https://maps.googleapis.com/maps/api/place/details/json?placeid=${val}&key=${GoogleApiKey}`,
  //   )
  //     .then(response => response.json())
  //     .then(res => {
  //       location = {
  //         latitude: res.result.geometry.location.lat,
  //         longitude: res.result.geometry.location.lng,
  //       };
  //     });
  //   return location;
  // }, 

  getAddress: async (lat, lng) => {
    var address = '';
    await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GoogleApiKey}`,
    )
      .then(response => response.json())
      .then(res => {
        address = res?.results[0]?.formatted_address;
      });

    
    return address;
  },
  
  getAllPrevMonth : () => {
    let monthList = [];
    const month = ["Jan","Feb","March","April","May","June","July","Aug","Sep","Oct","Nov","Dec"]
    const today = new Date()
    for (let i = 0; i < 12; i++) {
      const monthIndex = today.getMonth() - i;
      if (today.getMonth() - i >= 0) {
        var year =
        today.getFullYear() - Math.floor((today.getMonth() - i) / 12);
      } else {
        var year =
        today.getFullYear() - Math.floor((i - today.getMonth()) / 12);
      }
      const date = new Date(year, monthIndex, 2);
      monthList.push(month[date.getMonth()]);
    }

    return monthList.reverse()
},


convert24to12 : (time) => {
  if(time){
    let hours =
      Number(time.split(':')[0]) > 12
        ? Number(time.split(':')[0]) - 12
        : Number(time?.split(':')[0]) === 0
        ? 12
        : Number(time?.split(':')[0]);
    let minutes =
      Number(time.split(':')[1]) < 10
        ? `0${Number(time.split(':')[1])}`
        : Number(time.split(':')[1]);
    let meridian = Number(time.split(':')[0]) < 12 ? `AM` : `PM`;
    hours = Number(hours) < 10 ? "0"+Number(hours) : hours
    return `${hours}:${minutes} ${meridian}`;
  }
},

convert12to24 : (time) => {
  if(time?.includes("AM") || time?.includes("PM")){
      let newtime = time.split(":")
      if(time.includes("PM") && Number(time.split(":")[0]) !== 12){
        newtime[0] = Number(newtime[0]) + 12
      }else if(time.includes("AM") && Number(time.split(":")[0]) === 12){
        newtime[0] = "00"
      }
      newtime[0] = Number(newtime[0]) < 10 ? "0"+Number(newtime[0]) : newtime[0]
      newtime[1] = Number(newtime[1].split(" ")[0]) < 10 
                    ? "0"+Number(newtime[1].split(" ")[0]) 
                    : newtime[1].split(" ")[0]
     return newtime.toString().replace(",",":")
  } else{
    return time
  }
},


nextHour24: (time= "" ,minHour = "" ) => {
  let Hour = (Number(time.split(':')[0]) + minHour) 
  let newHour = Number(Hour) < 10 ? '0'+String(Hour) : Hour
  var Meridiem = Number(time.split(':')[1]);
  let newMeridiem = Number(Meridiem) < 10 ? '0'+String(Meridiem) : Meridiem
  var Time = [newHour, newMeridiem].join(':');
  return Time;
},

nextMinutes: (time, min) => {
  var Time = new Date(time).setTime(
    new Date(time).getTime() + min * 60 * 1000,
  );
  return new Date(Time);
},

nextMinutes1: (time, min) => {
 let data = Number(time.split(':')[1]) + Number(min)
 let newtime = Number(time.split(':')[0])
 while(data >= 60){
  data = data - 60 
  if(newtime >= 24){
    newtime = 0
  }

  newtime = newtime + 1
 }
 if(newtime >= 24){
  newtime = 0
}
if(data < 10){
  data = '0' + data 
}
if(newtime < 10){
  newtime = '0' + newtime 
}
return newtime +':'+ data

},

checkTimeDifference: (from, to, min) => {
  var From = new Date(from).getTime();
  var To = new Date(to).getTime();
  var Diff = To - From;
  return Diff > min * 60 * 1000;
},


  getTimeDif : (from_time,to_time) => {
  if(!from_time.includes("M")){
    if(to_time==="00:00") to_time=`24:${to_time.split(":")[1]}`
      let hours = Number(to_time.split(":")[0]) - Number(from_time.split(":")[0])
      let minutes = ""
      if(Number(to_time.split(":")[1]) - Number(from_time.split(":")[1]) < 0){
          hours -= 1 
          minutes = (60 - Number(from_time.split(":")[1])) + Number(to_time.split(":")[1])
      }else{
          minutes = Number(to_time.split(":")[1]) - Number(from_time.split(":")[1])
      }
      if(hours < 10){
          hours = `0${hours}`
      }
      if(minutes < 10){
          minutes = `0${minutes}`
      }
      return `${hours}:${minutes}`
  }
},

timeSince: (date) => {
  var seconds = Math.floor((new Date() - new Date(date).getTime()) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + ' years ago';
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + ' months ago';
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + ' days ago';
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + ' hours ago';
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + ' minutes ago';
  }
  return Math.floor(seconds) + ' seconds ago';
},

getPlaceDetail : (item) => {
  var obj = {
    city: '',
    state: '',
    country: '',
  };
  if (item.terms && item.terms.length) {
    item.terms.reverse().map((e, i) => {
      if (Object.keys(obj).reverse()[i]) {
        obj[Object.keys(obj).reverse()[i]] = e.value;
      }
    });
    obj.address = item?.description || '';
    obj = {...obj};
    // setValue(item.description);
  } else {
    var getData = item.address_components.reverse();
    const findCountry =
      getData?.find(e => {
        return e?.types?.find(val => {
          return val == 'country';
        });
      })?.long_name || '';
    const findState =
      getData?.find(e => {
        return e?.types?.find(val => {
          return val == 'administrative_area_level_1';
        });
      })?.long_name || '';
    const findCity =
      getData?.find(e => {
        return e?.types?.find(val => {
          return val == 'administrative_area_level_3';
        });
      })?.long_name || '';
      obj = {
        city: findCity,
        state: findState,
        country: findCountry,
      }
    }
    // console.log(obj,'objobjobj')
    // console.log(item,'itemitem')

return obj
},

numberWithDecimal : (val,dec) => {
  if(dec===0 && val && String(val).includes(".")) return String(val).split(".")[0]
  if(val && String(val).includes(".")) return String(val).split(".")[0] + "." + String(val).split(".")[1].slice(0,dec)
  return val
},

getOnlyDateStringFromDate : (date) => {
  let newDate = date ? new Date(date) : new Date()
  let datea = newDate.getDate()>9 ? newDate.getDate() : `0${newDate.getDate()}`
  let month = newDate.getMonth()>9 ? newDate.getMonth()+1 : `0${newDate.getMonth()+1}`
  let year = newDate.getFullYear()
  return `${year}-${month}-${datea}`
},

getOnlyDateDifference : (nextDate, prevDate) => {
      let nextDateTemp = nextDate ? nextDate : new Date().toISOString()
      let getNextDate = nextDateTemp.split("T")[0]
      let prevDateTemp = prevDate ? prevDate : new Date().toISOString()
      let getPrevDate = prevDateTemp.split("T")[0]
      let diff = new Date(getNextDate) - new Date(getPrevDate)
      return diff
  },

  inWords : (num, totalamt) => {
    if ((num = num.toString()).length > 9) return "overflow";
    let n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return;
    var str = "";
    str +=
      n[1] != 0
        ? (numInWords.a[Number(n[1])] ||
            numInWords.b[n[1][0]] + " " + numInWords.a[n[1][1]]) + "crore "
        : "";
    str +=
      n[2] != 0
        ? (numInWords.a[Number(n[2])] ||
            numInWords.b[n[2][0]] + " " + numInWords.a[n[2][1]]) + "lakh "
        : "";
    str +=
      n[3] != 0
        ? (numInWords.a[Number(n[3])] ||
            numInWords.b[n[3][0]] + " " + numInWords.a[n[3][1]]) + "thousand "
        : "";
    str +=
      n[4] != 0
        ? (numInWords.a[Number(n[4])] ||
            numInWords.b[n[4][0]] + " " + numInWords.a[n[4][1]]) + "hundred "
        : "";
    str +=
      n[5] != 0
        ? (str != "" && totalamt.split(".").length === 1 ? "and " : "") +
          (numInWords.a[Number(n[5])] ||
            numInWords.b[n[5][0]] + " " + numInWords.a[n[5][1]])
        : "";
    return str.toUpperCase();
  },

  jsonToExcel : ( arr, name) => {
    let date = new Date().toISOString().split(".")[0].replaceAll("-", "")
    const filename=`${name}${date}.xlsx`;
    let ws = XLSX.utils.json_to_sheet(arr);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb,filename);
  }


}