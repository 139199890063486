
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';




// const ScheduleAndCost = Loadable(lazy(() => import('./ScheduleAndCost')));        
const TurfTab = Loadable(lazy(() => import('./TurfTab')));

// const EditSlot = Loadable(lazy(() => import('./child/EditSlot')));                                                                                             
                                                                                        



const scheduleAndCostRoutes = [
  // { path: '/schedule-cost', element: <ScheduleAndCost/>},
  // { path: '/schedule-cost/:id', element: <ScheduleAndCost/>},
  { path: '/schedule-cost/:id', element: <TurfTab />},

]
export default scheduleAndCostRoutes;
