import { _get, _putAuth, convertObjToQueryStr } from "api/Apimethod";
import constant from "constant";

export const getTuftDetail = async () => {
  // let url = constant.Vendor.TurfProfile + '?vendor_id='+id
  let url = constant?.Vendor?.TurfProfile 
  const response = await _get(url);
  return response;
};
export const getGallery = async (id) => {
  let url = constant.Vendor.TurfProfile + '/'+id
  const response = await _get(url);
  return response;
};

export const vendorProfile = async () => {
  let url = constant.Vendor.vendorProfile;
  const response = await _get(url);
  return response;
}; 

export const getSingleTuftDetail = async (id) => {
  let url = constant?.Vendor?.SingleTurfProfile+"/"+id;
  const response = await _get(url);
  return response;
};
export const updateTurfDetail = async (data , id) => {
	let url = constant.Vendor.SingleTurfProfile +"/"+id
	const response = await _putAuth(url,data )
	return response
};

export const getBussinessDetail = async (id) => {
    let url = constant.Vendor.Bussiness_Detail+ '?id=' + id;
    const response = await _get(url);
    return response;
};

export const checkMobile = async (data) => {
  let url = constant.Vendor.Check_Mobile;
  let str = convertObjToQueryStr(data)
	url = url + str
  const response = await _get(url);
  return response;
};
  
export const editPersonalDetail = async (data,id) => {
  let url = constant.Vendor.vendor_profile+'/'+id;
  const response = await _putAuth(url, data);
  return response;
};

export const editProfile= async (data,id) => {
  let url = constant.Vendor.Edit_Personal_Detail+'/'+id;
  const response = await _putAuth(url, data);
  return response;
};

export const editBussinessDetail = async (data,id) => {
  let url = constant.Vendor.Edit_Bussiness_Detail+ '/' + id;
  const response = await _putAuth(url, data);
  return response;
};

export const VendorProfile = async (navigate) => {
	const url = constant.Vendor.vendor_profile;
	const response = await _get(url,navigate)
	return response
  }

export const managerProfile = async (navigate) => {
	const url = constant.Vendor.managerProfile;
	const response = await _get(url,navigate)
	return response
  }
export const scorerProfile = async (navigate) => {
	const url = constant.Vendor.scorerProfile;
	const response = await _get(url,navigate)
	return response
  }

  export const UpdateProfileimg = async (data, id) => {
    let url = constant.Vendor.edit_profileimg+"/"+id; 
    const response = await _putAuth(url, data)
    return response
    }