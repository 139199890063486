import { combineReducers } from 'redux';
// import EcommerceReducer from './EcommerceReducer';
import NavigationReducer from './NavigationReducer';
// import NotificationReducer from './NotificationReducer';
import VendoreLogin from './VendoreLogin';
import Otp from './Otp';
import  SignupOtp  from './SignupOtpSet';
import ProfileData from './ProfileData';
import OffilineBooking from './OffilineBooking';
import scoreSlice from './scoreSlice';
import matchSlice from './matchSlice';
import ExtendAndAmend  from './ExtendAmendData';
import AvailableSlotsData from './AvailableSlots';


const RootReducer = combineReducers({
  // notifications: NotificationReducer,
  navigations: NavigationReducer,
  LoginID: VendoreLogin,
  SetLoginOtp : Otp,
  setSignupOtp : SignupOtp,
  setProfileData : ProfileData,
  setOfflineData: OffilineBooking,
  match: matchSlice,
  score: scoreSlice,
  setExtendData: ExtendAndAmend,
  setAvailableData: AvailableSlotsData,
  

  // ecommerce: EcommerceReducer,
});

export default RootReducer;
