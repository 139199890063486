import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';





const BlogListing = Loadable(lazy(() => import("./BlogListing")));
const AddBlog = Loadable(lazy(() => import("./AddBlog")));
const EditBlog = Loadable(lazy(() => import("./EditBlog")));




const blogRoutes = [
    {
        path: "/blog-list",
        element: <BlogListing />,
        auth: authRoles.guest,
    },
  
    {
        path: "/blog-list/add-blog",
        element: <AddBlog/>,
        auth: authRoles.guest,
    },
    {
        path: "/blog-list/edit-blog/:id",
        element: <EditBlog/>,
        auth: authRoles.guest,
    },
    

];

export default blogRoutes;