import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';




const ContactSupport = Loadable(lazy(() => import("./ContactSupport")));


const contactAndSupportRoutes = [
    {
        path: "/contact-support",
        element: <ContactSupport />,
        auth: authRoles.guest,
    },
];

export default contactAndSupportRoutes;