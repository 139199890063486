import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';



const HolidayListing = Loadable(lazy(() => import("./HolidayListing")));





const holidayRoutes = [
    {
        path: "/holiday-slots",
        element: <HolidayListing />,
        auth: authRoles.guest,
    },
  

];

export default holidayRoutes;