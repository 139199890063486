import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { SettingsProvider } from "app/contexts/SettingsContext";
import { MatxTheme } from "app/components";
import { AuthProvider } from "app/contexts/JWTAuthContext";
import { Store } from "./app/redux/Store";
import { ToastContainer } from "react-toastify";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./Assets/css/dashboard.css"

const theme = createTheme({
  typography: {
    fontFamily: "Dosis-Medium",
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontFamily: "Dosis-SemiBold",
          fontSize: "16px",
          borderBottom: "1px solid #ececec",
        },
      },
    },
  },
});

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <CssBaseline />
      <Provider store={Store}>
        <SettingsProvider>
          <MatxTheme>
            <ThemeProvider theme={theme}>
              <AuthProvider>
                <App />
              </AuthProvider>
            </ThemeProvider>
            <ToastContainer />
          </MatxTheme>
        </SettingsProvider>
      </Provider>
    </BrowserRouter>
  </StyledEngineProvider>,
  document.getElementById("root")
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
