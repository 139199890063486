import Loadable from "app/components/Loadable";
import { lazy } from "react";


const ManagerList = Loadable(lazy(() => import("./ManagerList")));
const AddManager = Loadable(lazy(() => import("./AddManager")));



const scorerRoute = [
    {
        path: "/manager/list",
        element: <ManagerList />,
    },
    {
        path: "/manager/add",
        element: <AddManager />,
    },
];

export default scorerRoute;