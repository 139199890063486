import {
  Button,
  Grid,
  InputLabel, TextField
} from "@mui/material";
import { setProfileData } from "app/redux/reducers/ProfileData";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { MdVerified } from "react-icons/md";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { generateOtpAadhar, getKYC, verifyAdharOtpValue, verifyPANNumber } from "services/web/Kyc";


export default function AddKYC() {
  const [panNumber, setPanNumber] = useState({ number:"", verified: false, error: false, loader:false, message:"Required" })
  const [adharNumber, setAdharNumber] = useState({ number:"", verified: false, error: false, loader:false, client_id:"", message:"Required"})
  const [otpValue, setOtpValue] = useState("")
  const [otpObj, setOtpObj] = useState({ show : false, error: false,message:"Required", loader:false  })
  const Data = useSelector((state) => state.setProfileData.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();


  const verifyPAN = async () => {
    let isValidated = panNumber.number.match("[A-Z]{5}[0-9]{4}[A-Z]{1}")
    if(!isValidated){
      setPanNumber({ ...panNumber, error:true, message:panNumber.number ? 'Invalid PAN' : "Required" })
    }else{
      setPanNumber({ ...panNumber,loader : true})
      const params = { id_number: panNumber.number }
      await verifyPANNumber(params).then((res)=> {
        setPanNumber((prevState)=> {
          let temp = { ...prevState}
          temp.loader = false
          if(res?.status && res?.data?.full_name){
              temp.verified = true
              temp.error = false
            }else{
              temp.error = true
              temp.message = res.message
            }
            return temp
        })
    }).catch((err)=> {
      console.log(err,'err')
      setPanNumber({ ...panNumber,loader : false})
      })
    }
  }

  const sendOTPAdhar = async () => {
    let isValidated = adharNumber.number.match("^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$")
    if(!isValidated){
      setAdharNumber({ ...adharNumber, error:true,message:adharNumber.number ? 'Invalid Aadhar' : "Required" })
    }else{
      setAdharNumber({ ...adharNumber,loader : true})
      const params = { id_number: adharNumber.number }
      await generateOtpAadhar(params).then((res)=> {
        setAdharNumber((prevState)=> {
          let temp = { ...prevState}
          temp.loader = false
          if(res?.status && res?.data?.client_id){
            temp.error = false;
            temp.client_id = res.data.client_id;
            setOtpObj({ ...otpObj, show:true})
          }else{
            temp.error = true
            temp.message = res.message
          }
          return temp
        })
      }).catch((err)=> {
        console.log(err,'err')
        setAdharNumber({ ...adharNumber,loader : false})
      })
    }
  }


  const verifyAdharOtp = async () => {
    let isValidated = adharNumber.number.match("^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$")
    if(!otpValue || (otpValue && otpValue.length<6)){
      setOtpObj({ ...otpObj, error:true,message:otpValue ? "Enter 6 Digit OTP" : 'Required'})
    }else if(!isValidated){
      setAdharNumber({ ...adharNumber, error:true, message:adharNumber.number ? 'Invalid Aadhar' : "Required" })
    }else{
      setOtpObj({ ...otpObj, loader:true})
      const params = { client_id: adharNumber.client_id, otp: otpValue }
      await verifyAdharOtpValue(params).then((res)=> {
        if(res.status){
            setAdharNumber({ ...adharNumber, verified:true})
            setOtpObj({ ...otpObj, loader:false, show:false})
          }else{
            setOtpObj({ ...otpObj, loader:false, message:res.message})
        }
      }).catch((err)=> {
        console.log(err,'err')
        setOtpObj({ ...otpObj, loader:false})
      })
    }
  }

  const getKYCInfo = async () => {
    const params = { client_id: adharNumber, otp: otpValue }
    await getKYC(params).then((res)=> {
      if(res?.status && res?.data){
        let pan = res.data?.pan
        setPanNumber({ ...panNumber, number: pan?.number , verified: pan?.is_verified ? true : false})
        let aadhar = res.data?.aadhar
        setAdharNumber({ ...adharNumber, number: aadhar?.number, verified: aadhar?.is_verified ? true : false})
      }
    }).catch((err)=> {
      console.log(err,'err')
    })
  }

  useEffect(() => {
    getKYCInfo()
  }, [])
  
  const pressOkay = () => {
    let isValidatedPAN = panNumber.number.match("[A-Z]{5}[0-9]{4}[A-Z]{1}")
    let isValidatedAdhar = adharNumber.number.match("^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$")
    if(panNumber.verified && adharNumber.verified){
      let profileData = { ...Data }
      profileData.is_kycUploaded = 1
      dispatch(setProfileData(profileData))
      navigate("/vendor-dashboard")
    }else if(isValidatedAdhar){
      setPanNumber({ ...panNumber, error:true, message: panNumber.number ? 'Invalid Pan' : 'Required' })
    }else if(isValidatedPAN){
      setAdharNumber({ ...adharNumber, error:true, message: adharNumber.number ? 'Invalid Pan' : 'Required' })
    }else{
      setPanNumber({ ...panNumber, error:true, message: panNumber.number ? 'Invalid Pan' : 'Required' })
      setAdharNumber({ ...adharNumber, error:true, message: adharNumber.number ? 'Invalid Pan' : 'Required' })
    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    if(name==='pan'){
      let isValidated = value && value.match("[A-Z]{5}[0-9]{4}[A-Z]{1}")
      setPanNumber((temp)=> ({
        ...temp,
        number: value,
        error: isValidated ? false : true,
        message: isValidated ? "" : "Pan Required",
      }))
    }else{
      let isValidated = value && value.match("^[0-9]{4}[ -]?[0-9]{4}[ -]?[0-9]{4}$")
      setOtpObj({ ...otpObj, show:false})
      setAdharNumber((temp)=> ({
        ...temp,
        number: value,
        error: isValidated ? false : true,
        message: isValidated ? "" : "Aadhar Required",
      }))
    }
  }

  return (
    <div className="content-body rightside-event main-dash">
      <div className="container-fluid">
        <div className="details" style={{ background: "white" }}>
          <div className="p-4 pb-3">
            <h2 className="h2-chart">KYC Verification</h2>
          </div>
          <div className="row">
          <div className="col-md-6 pb-4">
            <form
              className="add-turf-select ms-4"
              // onSubmit={handleSubmit}
              id="add-turf"
            >
              <Grid container spacing={3} sx={{ mb: 2 }}>
                <Grid item lg={8} md={6} sm={12} xs={12}>
                  <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">Pan Number*</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    // size='small'
                    name="pan"
                    id="input-with-icon-textfield"
                    variant="outlined"
                    disabled={panNumber.verified}
                    placeholder="Enter PAN Number"
                    value={panNumber.number}
                    onChange={(e)=> handleChange(e)}
                    helperText={panNumber.error ? panNumber.message : "" }
                    error={Boolean(panNumber.error)}
                    InputProps={{
                      endAdornment: (
                        <React.Fragment>
                          <Button
                            style={{ background: "#333",height:"38px",fontFamily: "Oswald-Regular" }}
                            color="primary"
                            variant="contained"
                            type="button"
                            onClick={()=> !panNumber.verified && verifyPAN()}>
                              {
                                panNumber.verified
                                ? <span className="d-flex justify-content-center align-items-center">{ <MdVerified className="me-1" /> }Verified</span>
                                : panNumber.loader
                                ? <Spinner size='sm' />
                                : "Verify"
                              }
                          </Button>
                        </React.Fragment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item lg={8} md={6} sm={12} xs={12}>
                  <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">Aadhar Number*</InputLabel>
                  <TextField
                    type="text"
                    fullWidth
                    // size='small'
                    name="adhar"
                    id="input-with-icon-textfield"
                    variant="outlined"
                    placeholder="Enter Aadhar Number"
                    value={adharNumber.number}
                    disabled={adharNumber.verified}
                    onChange={(e)=> handleChange(e)}
                    helperText={adharNumber.error ? adharNumber.message : "" }
                    error={Boolean(adharNumber.error)}
                    InputProps={{
                      endAdornment: (
                        <React.Fragment>
                          <Button
                            style={{ background: "#333",height:"38px",fontFamily: "Oswald-Regular", whiteSpace: 'nowrap' }}
                            color="primary"
                            variant="contained"
                            type="button"
                            onClick={()=> !adharNumber.verified && sendOTPAdhar()}>
                                {
                                  adharNumber.verified
                                  ? <span className="d-flex justify-content-center align-items-center">{ <MdVerified className="me-1" /> }Verified</span>
                                  : adharNumber.loader
                                  ? <Spinner size='sm' />
                                  : "Send OTP"
                                }
                          </Button>
                        </React.Fragment>
                      ),
                    }}
                  />
                </Grid>

              {otpObj.show && 
                <Grid item lg={8} md={6} sm={12} xs={12}>
                  <p
                    className="mt-2 vendor-p mb-2"
                    style={{ color: "#33333399" }}
                  >
                    Please enter the OTP sent to your adhar linked mobile number
                  </p>
                  <div className="otp">
                    <OtpInput
                      name="otp"
                      value={otpValue}
                      onChange={setOtpValue}
                      numInputs={6}
                      className="inputStyle"
                      inputStyle={{
                        width: "45px",
                        textAlign: "center",
                        height: " 45px",
                        border: "1px solid #E0E0E0",
                        padding: " 0px",
                        margin: "0px 10px 0px 0px",
                        borderRadius: "5px"
                      }}
                      />
                      {otpObj.error && <p className="text-danger fs-14 mb-0 ps-2">{otpObj.message}</p>}
                  </div>
                    <Button
                      className="mt-2"
                      style={{ background: "#333",height:"38px",fontFamily: "Oswald-Regular", whiteSpace: 'nowrap' }}
                      color="primary"
                      variant="contained"
                      type="button"
                      onClick={verifyAdharOtp}>
                        {otpObj.loader ? <Spinner size='sm' /> : "Verify"}
                    </Button>
                </Grid>
              }

                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <Button
                    fullWidth
                    className='mt-3'
                    style={{ background: "#333",height:"38px",fontFamily: "Oswald-Regular", whiteSpace: 'nowrap' }}
                    color="primary"
                    variant="contained"
                    type="button" 
                    onClick={pressOkay}>
                    Okay
                  </Button>
                </Grid>


              </Grid>
            </form>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

