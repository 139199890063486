import React from 'react'
import AddBusiness from './AddBusiness';
import AddKYC from './AddKYC';
import AccountDetail from './AccountDetail';


// const AddBusiness = Loadable(lazy(() => import("./AddBusiness")));

const VerifyVendorRoute = [
    {
        path: "/vendor-verify",
        element: <AddBusiness />,
        // auth: authRoles.guest,
    },
    {
        path: "/vendor-addKYC",
        element: <AddKYC />,
        // auth: authRoles.guest,
    },
    {
        path: "/vendor-account-detail",
        element: <AccountDetail />,
        // auth: authRoles.guest,
    },

];

export default VerifyVendorRoute;