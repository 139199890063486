import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';
import OccupancyPage from "./dashboardCards/occupancy/OccupancyPage";
import { Navigate } from 'react-router-dom';



const Dashboard = Loadable(lazy(() => import("./Dashboard")));
const ReviewChart = Loadable(lazy(() => import("./dashboardCards/ReviewChart")));
const BookingChart = Loadable(lazy(() => import("./dashboardCards/BookingChart")));
const RevenuaChart = Loadable(lazy(() => import("./dashboardCards/RevenueChart")));
const UserChart = Loadable(lazy(() => import("./dashboardCards/UserChart")));




const vendorDashboardRoutes = [ 
    {
        path: "/vendor-dashboard",
        element: <Dashboard/>,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-dashboard/:id",
        element: <Dashboard/>,
        auth: authRoles.guest,
    },
    {
        path: "/user-chart",
        element: <UserChart/>,
        auth: authRoles.guest,
    },
    
    {
        path: "/review-chart",
        element: <ReviewChart />,
        auth: authRoles.guest,
    },
    
    {
        path: "/booking-chart",
        element: <BookingChart />,
        auth: authRoles.guest,
    },
    
    {
        path: "/revenue-chart",
        element: <RevenuaChart />,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-occupancy",
        element: <OccupancyPage />,
        auth: authRoles.guest,
    },
    // { path: '/*', element: <Navigate to="/vendor-dashboard"/> }, 


  
];

export default vendorDashboardRoutes;