import { createSlice } from "@reduxjs/toolkit"

export const Otp = createSlice({
	name: 'Otp',
	initialState: {
		otp: '',
	},
	reducers: {
		setLoginOtp: (state, action) => {
			state.otp = action.payload
		},

		// setSignupOtp: (state, action) => {
		// 	state.signupOtp = action.payload
		// },
	},

})

// Action creators are generated for each case reducer function
export const { setLoginOtp } = Otp.actions

export default Otp.reducer