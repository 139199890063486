import React from 'react';
import useSettings from 'app/hooks/useSettings';
import { WebLayouts } from '.';
import { MatxSuspense } from 'app/components';

const WebLayout = (props) => {
    const { settings } = useSettings();
    const TopLAyout = WebLayouts[settings.activeLayout];

    return (
        <MatxSuspense>
          <TopLAyout {...props} />
        </MatxSuspense>
      );

}

export default WebLayout;
