
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const TermAndCondition = Loadable(lazy(() => import('./TermAndCondition')));                                                                                             



const termsAndConditionRoutes = [
  { path: '/term-condition', element: <TermAndCondition/>},
]
export default termsAndConditionRoutes;
