import CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET_KEY;
const nodeEnv = process.env.REACT_APP_NODE_ENV;

const base64UrlEncode = (str) => {
  return btoa(str)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
}
const base64UrlDecode = (str) => {
  let base64 = str.replace(/-/g, '+').replace(/_/g, '/');
  while (base64.length % 4) {
    base64 += '=';
  }
  return atob(base64);
}

export const encryptData = (text) => {
  if(text && nodeEnv==='PRODUCTION'){
    const encrypted = CryptoJS.AES.encrypt(text, secretKey).toString();
    const urlSafeEncrypted = base64UrlEncode(encrypted);
    return urlSafeEncrypted;
  }
  return text
};

export const decryptData = (encryptedText) => {
  if (encryptedText && nodeEnv==='PRODUCTION') {
    const decoded = base64UrlDecode(encryptedText);
    const bytes = CryptoJS.AES.decrypt(decoded, secretKey);
    const originalText = bytes.toString(CryptoJS.enc.Utf8);
    return originalText;
  }
  return encryptedText
};