import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import { decryptData } from "app/helperFuntion/encryptDecrypt";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getAvailableAndBookedSlot } from "services/vendor/Occupancy";

const OccupancyPage = () => {
  const [occupancyList, setOccupancyList] = useState([]);
  const [currentDate, setCurrentDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [selectedDate, setSelectedDate] = useState("");

  const [availableAndBookedSlot, setAvailableAndBookedSlot] = useState({
    booked: 0,
    available: 0,
    markedBooked: 0,
    total: 0,
  });
  const { id } = useParams()

  const occupancyListing = async (id, date) => {

    setSelectedDate(date)
    // setSeletedDate(date)
    let params = {
      spot_id: decryptData(id),
      date: date ? date : currentDate,
    };

    // console.log('params', params)
    await getAvailableAndBookedSlot(params).then((res) => {
      if (res?.data?.status) {
        console.log(res, 'resres')
        setOccupancyList(res?.data?.data);
        GetSlotDetail(res?.data?.data);
      }
    });
  };

  const GetSlotDetail = (data) => {
    let newData = {
      booked: 0,
      available: 0,
      total: 0,
      markedBooked: 0,
    };
    data &&
      data.length > 0 &&
      data.map((e) => {
        newData.total += 1;
        if (e?.is_booked === "AVAILABLE") {
          newData.available += 1;
        }
        if (e.is_booked === "CONFIRMED") {
          newData.booked += 1;
        }
        if (e.is_booked === "BOOKMARKED") {
          newData.markedBooked += 1;
        }
      });
    setAvailableAndBookedSlot({ ...newData });
  };
  useEffect(() => {
    occupancyListing(id, selectedDate)
  }, [id]);


  const BookingStatus = {
    CONFIRMED: "Booked",
    AVAILABLE: "Available",
    BOOKMARKED: "Marked Booked",
  };



  return (
    <div
      className="top-banner main-box Occupancy-box"
      style={{ height: "600px", overflow: 'auto' }}
    >
      <div
        className="d-flex justify-content-between mt-2"
        style={{ gap: "13px" }}
      >

        <h4 className="vendor-h4">Occupancy</h4>
        <div className="ocuupancy-date">
          <TextField
            type="date"
            defaultValue={currentDate}
            onKeyDown={(e) => e.preventDefault()}
            onChange={(e) => {
              occupancyListing(id, e.target.value);
            }}
            inputProps={{
              style: { padding: "8px" },
              min: currentDate,
            }}
          />

        </div>
      </div>
      <div className="main-custom d-flex align-items-center pt-2">
        <div className="slots-box me-4">
          <h6 className="vendor-h6">
            Total Slots - {availableAndBookedSlot.total}{" "}
          </h6>
        </div>

        <div className="d-flex">
          <h6 className="custom-h5 pe-2 vendor-h6">
            <span className="mark-booked"></span>Booked-{" "}
            {availableAndBookedSlot.booked}
          </h6>
          <h6 className="custom-h5 pe-2 vendor-h6">
            <span className="success"></span>Available-{" "}
            {availableAndBookedSlot.available}
          </h6>
          <h6 className="custom-h5 vendor-h6">
            <span className="booked"></span>Marked Booked-{" "}
            {availableAndBookedSlot.markedBooked}
          </h6>
        </div>
      </div>
      <TableContainer>
        <Table sx={{ minWidth: 445 }} aria-label="simple table">
          <TableHead>
            <TableRow className="table-tr">
              <TableCell align="center">From</TableCell>
              <TableCell align="center">To</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {occupancyList?.length > 0 ? (
              occupancyList &&
              occupancyList?.length > 0 &&
              occupancyList?.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell align="center" >
                      {item?.from_time}
                    </TableCell>
                    <TableCell align="center" >{item?.to_time}</TableCell>
                    <TableCell
                      align="center"
                      style={{
                        color: `${item.is_booked==="CONFIRMED" ? "#ff5722" : item.is_booked==="BOOKMARKED" ? '#de3163' : "#25ab53"}`
                      }}
                    >
                      {BookingStatus[item?.is_booked]}
                    </TableCell>
                    <TableCell align="center">{item?.amount}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan="4" className="text-center">
                  Data Not Available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default OccupancyPage;
