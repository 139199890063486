
import { _get, _patch, _postAuth, _put, _putAuth } from "api/Apimethod"
import constant from "constant"

export const uploadKycDoc = async (data) =>{
	let url = constant.Vendor.Kyc.PostKyc
	const response = await _postAuth(url ,data);
	return response;
}

export const updateKycDoc = async (data) =>{
	let url = constant.Vendor.Kyc.UpdateKyc
	const response = await _putAuth(url, data);
	return response;
}


export const verifyPANNumber = async (data) =>{
	let url = constant.Vendor.Kyc.verifyPAN
	const response = await _postAuth(url, data);
	return response;
}

export const generateOtpAadhar = async (data) =>{
	let url = constant.Vendor.Kyc.generateOtpAadhar
	const response = await _postAuth(url, data);
	return response;
}

export const verifyAdharOtpValue = async (data) =>{
	let url = constant.Vendor.Kyc.verifyAdharOtp
	const response = await _postAuth(url, data);
	return response;
}

export const getKYC = async (data) =>{
	let url = constant.Vendor.Kyc.GetKyc
	const response = await _get(url, data);
	return response?.data ? response.data : response;
}


