import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';


const TournamentPage = Loadable(lazy(() => import("./TournamentPage")));
const AddTournament = Loadable(lazy(() => import("./AddTournament")));
const EditTournament = Loadable(lazy(() => import("./EditTournament")));
const TournamentDetails = Loadable(lazy(() => import("./TournamentDetails")));




const vendorTournamentRoutes = [
    {
        path: "/tournament/:added_by",
        element: <TournamentPage />,
        auth: authRoles.guest,
    },
    {
        path: "/tournament/:added_by/add",
        element: <AddTournament />,
        auth: authRoles.guest,
    },
    {
        path: "/tournament/:added_by/edit/:id",
        element: <EditTournament />,
        auth: authRoles.guest,
    },
    {
        path: "/tournament/:added_by/tournament-detail/:id",
        element: <TournamentDetails />,
        auth: authRoles.guest,
    },

];

export default vendorTournamentRoutes;