
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const Share = Loadable(lazy(() => import('./Share')));                                                                                             



const shareRoutes = [
  { path: '/share/:screen/:id', element: <Share/>},
]
export default shareRoutes;
