
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';

const PrivacyAndpolicy = Loadable(lazy(() => import('./PrivacyAndpolicy')));                                                                                             



const privacyRoutes = [
  { path: '/privacy-policy', element: <PrivacyAndpolicy/>},
]
export default privacyRoutes;
