import { _get, _patch,_postAuth } from "api/Apimethod";
import constant from "constant";
import { convertObjToQueryStr } from "api/Apimethod";

export const getDasthboard = async (data) => {
  let url = constant.Admin.Dashboard;
  if (data) {
    let str = convertObjToQueryStr(data);
    url = url + str;
  }
  const response = await _get(url);
  return response;
};

export const getTournamentAnalytics = async () => {
  let url = constant.Admin.tournamentAnalytics;
  const response = await _get(url);
  return response;
};
export const getSportAnalytics = async () => {
  let url = constant.Admin.SportAnalytics;
  const response = await _get(url);
  return response;
};

export const getBatAnalytics = async (params) => {
  let url = constant.Admin.BatAnalytics;
  let str = convertObjToQueryStr(params)
  url = url+str
  const response = await _get(url);
  return response;
};

export const getBatAnalyticsOverall = async (params) => {
  let url = constant.Admin.BatAnalyticsOverall;
  let str = convertObjToQueryStr(params)
  url = url+str
  const response = await _get(url);
  return response;
};

export const getBallAnalyticsOverall = async (params) => {
  let url = constant.Admin.BallAnalyticsOverall;
  let str = convertObjToQueryStr(params)
  url = url+str
  const response = await _get(url);
  return response;
};
export const getBallAnalytics = async (params) => {
  let url = constant.Admin.BallAnalytics;
  let str = convertObjToQueryStr(params)
  url = url+str
  const response = await _get(url);
  return response;
};

export const getMVPAnalytics = async (params) => {
  let url = constant.Admin.MVPAnalytics;
  let str = convertObjToQueryStr(params)
  url = url+str
  const response = await _get(url);
  return response;
};

export const getMVPAnalyticsOverall = async (params) => {
  let url = constant.Admin.MVPAnalyticsOverall;
  let str = convertObjToQueryStr(params)
  url = url+str
  const response = await _get(url);
  return response;
};

export const getPlayerDetails = async (params) => {
  let url = constant.Admin.PlayerDetail;
  let str = convertObjToQueryStr(params)
  url = url+str
  const response = await _get(url);
  return response;
};

export const getPlayerStats = async (params) => {
  let url = constant.Admin.PlayerState;
  let str = convertObjToQueryStr(params)
  url = url+str
  const response = await _get(url);
  return response;
};


export const getMaintenanceDetail = async () => {
	let url = constant.Admin.MaintenanceDetail
	const response = await _get(url)
	return response
  }


// 

export const AdminMaintenance = async (data) => {
	let url = constant.Admin.Maintenance
	const response = await _patch(url,data)
	return response;
  };

export const Addannouncement = async (data) =>{
  let url = constant.Admin.addAnnouncement
  const response = await _postAuth(url, data)
  return response
}