import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";



const ReportList = Loadable(lazy(() => import("./ReportList")));



const reportRoutes = [
    {
        path: "/report-list",
        element: <ReportList />,
        auth: authRoles.guest,
    },


  
];

export default reportRoutes;