import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";




const AddPromoCode = Loadable(lazy(() => import("./AddPromoCode")));
const EditPromoCode = Loadable(lazy(() => import("./EditPromoCode")));
const PromoCodeListing = Loadable(lazy(() => import("./PromoCodeListing")));





const promoCodeRoutes = [
    {
        path:"/promocode/promocode-list",
        element:<PromoCodeListing/>,
        auth:authRoles.guest,
    },
    {
        path: "/promocode/add-promocode",
        element: <AddPromoCode />,
        auth: authRoles.guest,
    },
    {
        path: "/promocode/edit-promocode/:id",
        element: <EditPromoCode />,
        auth: authRoles.guest,
    },

    

];

export default promoCodeRoutes;