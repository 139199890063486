import React from 'react'
import { VendorNotification } from './VendorNotification'
import { authRoles } from '../../../auth/authRoles';


// const VendorNotification = Loadable(lazy(() => import("./VendorNotification")));


const NotificationRoute =[
    {
        path: "/vendor-notification",
        element: <VendorNotification />,
        auth: authRoles.guest,
    },
]
    
export default NotificationRoute