import {
  Autocomplete,
  Checkbox,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import { Span } from "app/components/Typography";
import { Helper } from "app/helperFuntion";
import { IncreaseTurfCount } from "app/redux/reducers/ProfileData";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CreateTimeAndCost } from "services/vendor/ScheduleAndCost";
import {
  addTurf,
  getAminityListVendor,
  getRulesListVendor
} from "services/web/Turf";
import * as Yup from "yup";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let initialValues = {
  spot_logo: "",
  spot_name: "",
  area: "",
  description: "",
  address: "",
  rules: [],
  spot_banner: "",
  aminities: [],
  open_time: "",
  location: "",
  closing_time: "",
  spot_type: "TURF",
  amount: ""
};

export default function AddBusiness() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [slotValue1, setSlotValue1] = useState(60);
  const [getData, setGetData] = useState({});
  const [aminityData, setAminityData] = useState([]);
  const [rule, setRule] = useState([]);
  const [cities, setCities] = useState([]);
  const [inputCity, setInputCity] = useState("");
  const [inputLoading, setInputLoading] = useState(false);
  const [loader, setLoader] = useState(false)
  const [isFullDaySlot, setIsFullDaySlot] = useState(false)


  const validationSchema = Yup.object().shape({
    rules: Yup.array()
      .min(1, "Special instructions are required")
      .required("Special instructions are required"),
    aminities: Yup.array()
      .min(1, " Aminities are required")
      .required("Aminities sre required"),
    spot_name: Yup.string().max(50, 'Maximum 50 letters').required("Business name is required"),
    address: Yup.string().max(50, 'Maximum 50 letters').required("Address is required"),
    open_time: Yup.string().test('global-ok', "Invalid Time", function (value) {
      if (this.parent.closing_time === value && this.parent.closing_time) {
        return false
      } else { return true }
    }).required("Open time is required"),
    closing_time: Yup.string().test('global-ok', "Invalid Time", function (value) {
      if (this.parent.open_time === value && this.parent.open_time) {
        return false
      } else { return true }
    }).required("Closing time is required"),

    amount: Yup.string().matches('^[1-9][0-9]*$', 'Please enter valid price').max(5, 'Maximum 99999 number').required("Slot price is required"),
    location: Yup.string().required("Location is required"),
    description: Yup.string()
      // .min(150, "Minimum 150 letter is required")
      // .max(500, "Maximum 500 letter is required")
      .required("Description is required"),
  });

  const AminityList = async () => {
    await getAminityListVendor().then((res) => {
      setAminityData(res?.data.data);
    });
  };

  const RuleList = async () => {
    await getRulesListVendor().then((res) => {
      setRule(res?.data.data);
    });
  };

  const formik = useFormik({
    initialValues: { ...initialValues },
    enableReinitializ: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoader(true)
      let openTime = values.open_time;
      let opening = Helper.convert24to12(openTime);
      let closeTime = values.closing_time;
      let closing = Helper.convert24to12(closeTime);

      let data = new FormData();
      data.append("spot_type", values.spot_type);
      data.append("spot_name", values.spot_name);
      data.append("address", values.address);
      data.append("area", values.area);
      data.append("spot_logo", values.spot_logo);
      data.append("spot_banner", values.spot_banner);
      data.append("rules", JSON.stringify(values.rules));
      data.append("aminities", JSON.stringify(values.aminities));
      data.append("open_time", opening);
      data.append("closing_time", closing);
      data.append("description", values.description);
      data.append("city", getData.city);
      data.append("state", getData.state);
      data.append("country", getData.country);
      data.append("latitude", getData.latitude);
      data.append("longitude", getData.longitude);
      data.append("duration", slotValue1);
      await addTurf(data).then((res) => {
        setLoader(false)
        if (res.status) {
          let obj = {
            spot_id: res.data._id,
            newOldData: handleSubmitData1(),
          };
          for (let i = 0; i < obj.newOldData.length; i++) {
            obj.newOldData[i] = {
              ...obj.newOldData[i],
              from_time: Helper.convert24to12(obj.newOldData[i].from_time),
              to_time: Helper.convert24to12(obj.newOldData[i].to_time),
            };
          }
          if (obj.newOldData[0].from_time === obj.newOldData[obj.newOldData.length - 1].to_time) {
            obj.newOldData[obj.newOldData.length - 1].to_time = '11:59 PM'
          }
          CreateTimeAndCost(obj).then((res) => {
          });
          dispatch(IncreaseTurfCount())
          navigate(`/ground/${res.data._id}`);
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      });
    },
  });

  const handleSubmitData1 = () => {
    let open_time = values.open_time.includes("M")
      ? Helper.convert12to24(values.open_time)
      : values.open_time;
    let closing_time = values.closing_time.includes("M")
      ? Helper.convert12to24(values.closing_time)
      : values.closing_time;
    let arr = [
      {
        from_time: open_time,
        to_time: Helper.nextMinutes1(open_time, slotValue1),
        amount: values.amount,
      },
    ];
    while (Helper.checkDifference24(arr[arr.length - 1].to_time, closing_time) > 0
      && arr[arr.length - 1].to_time.split(":")[0] !== "00"
    ) {
      if (Helper.checkDifference24(Helper.nextMinutes1(arr[arr.length - 1].to_time, slotValue1), closing_time) >= 0
      ) {
        arr.push({
          from_time: arr[arr.length - 1].to_time,
          to_time: Helper.nextMinutes1(arr[arr.length - 1].to_time, slotValue1),
          amount: values.amount,
        });
      } else {
        break;
      }
    }
    return arr;
  };

  const handleChangeSlot = async (e) => {
    setSlotValue1(e.target.value);
  };

  const getAddres = async (option) => {
    var latLngAddress = await Helper.PlaceId(option.place_id, true);
    setGetData({ ...latLngAddress });
  };

  useEffect(() => {
    AminityList();
  }, []);

  useEffect(() => {
    RuleList();
  }, []);

  const handleAmount = (e) => {
    if (e.target.value[0] === "0") {
    } else {
      setFieldValue(e.target.name, e.target.value);
    }
  }


  const {
    values,
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldValue,
    getFieldProps,
  } = formik;


  const checkUnderSpotTiming = (e) => {
    const { name, value } = e.target;
    if (name === 'open_time' && values?.closing_time) {
      if (Helper.checkDifference24(value, values.closing_time) > 0) setFieldValue("open_time", value)
    } else if (name === 'closing_time' && values?.open_time) {
      if (Helper.checkDifference24(values.open_time, value) > 0) setFieldValue("closing_time", value)
    } else {
      setFieldValue(name, value)
    }
  }

  return (
    <div className="content-body rightside-event main-dash">
      <div className="container-fluid">
        <div className="details p-3" style={{ background: "white" }}>
          <div className="heading mb-5">
            <h4 className="vendor-h4">
              Business Details{" "}
            </h4>
          </div>

          <form
            className="add-turf-select select-input"
            onSubmit={handleSubmit}
            id="add-turf"
          >
            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-md-4 mb-3">

                    <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">Property Type</InputLabel>
                    <div className="select-truf-ground mb-3">
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.spot_type}
                          name="spot_type"
                          onChange={handleChange}
                        >
                          {/* <MenuItem value="" disabled>Name of</MenuItem> */}
                          <MenuItem value="TURF">Turf</MenuItem>
                          <MenuItem value="GROUND">Ground</MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>

                  <div className="col-md-4 mb-4">
                    <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">Business Name*</InputLabel>
                    <TextField
                      type="text"
                      fullWidth
                      name="spot_name"
                      id="outlined-basic"
                      variant="outlined"
                      className="turf-vendor1"
                      // label="Turf name *"
                      placeholder="Name of the turf/ground"
                      InputLabelProps={{ shrink: true }}
                      value={values?.spot_name}
                      {...getFieldProps("spot_name")}
                      helperText={touched.spot_name && errors.spot_name}
                      error={Boolean(errors.spot_name && touched.spot_name)}
                    />
                  </div>

                  <div className="col-md-4 mb-4">
                    <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">Complete Address*</InputLabel>
                    <TextField
                      type="text"
                      fullWidth
                      name="address"
                      id="outlined-basic"
                      variant="outlined"
                      className="turf-vendor1"
                      placeholder="Enter complete address"
                      InputLabelProps={{ shrink: true }}
                      value={values?.address}
                      {...getFieldProps("address")}
                      helperText={touched.address && errors.address}
                      error={Boolean(errors.address && touched.address)}
                    />
                  </div>

                  <div className="col-md-4 mb-4">
                    <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">Search Location*</InputLabel>

                    <Autocomplete
                      disablePortal
                      disableClearable
                      id="combo-box-demo"
                      options={cities}
                      getOptionLabel={(cities) => cities?.description}
                      onChange={(e, value) => {
                        setFieldValue(
                          "location",
                          value?.description || ""
                        );
                        setInputCity(value?.description || "");
                        getAddres(value);
                        setCities([]);
                      }}
                      noOptionsText={
                        (cities?.length > 0 && "No Option") ||
                        "Type Your Location"
                      }
                      fullWidth
                      inputValue={inputCity}
                      renderInput={(params) => {
                        return (
                          <>
                            <TextField
                              {...params}
                              onChange={async (event, newInputValue) => {
                                setInputLoading(true);
                                setInputCity(event?.target?.value);
                                let arr = await Helper.getPlaceListAll(
                                  event?.target?.value
                                );
                                setCities(arr);
                                setInputLoading(false);
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {inputLoading ? (
                                      <CircularProgress
                                        color="inherit"
                                        size={20}
                                      />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </React.Fragment>
                                ),
                              }}
                              helperText={
                                touched.location && errors.location
                              }
                              error={Boolean(
                                errors.location && touched.location
                              )}
                              placeholder="Location of your Turf"
                            />
                          </>
                        );
                      }}
                    />

                  </div>

                  <div className="col-md-4 mb-4">
                    <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">Amenities*</InputLabel>
                    <FormControl
                      style={{ width: "100%" }}
                      error={Boolean(errors.aminities && touched.aminities)}
                    // style={{border: "1px solid #26377A", padding: "12px", borderRadius:"4px", width: "100%"}}
                    >
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        variant="outlined"
                        displayEmpty
                        className="turf-amenity-select"
                        value={values.aminities}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <span style={{ color: "#d1d0d1" }}>
                                Choose the amenities.
                              </span>
                            );
                          }
                          let data = aminityData.reduce((acc, cur) => {
                            if (selected.includes(cur._id)) {
                              acc.push(cur.name)
                            }
                            return acc
                          }, [])
                          return data.join()

                          // return SelectedAminitys(selected);
                        }}
                        MenuProps={MenuProps}
                        {...getFieldProps("aminities")}
                        helperText={touched.aminities && errors.aminities}
                      >
                        {aminityData &&
                          aminityData.length > 0 &&
                          aminityData?.map((item, index) => {
                            return (
                              <MenuItem key={index} value={item?._id}>
                                <Checkbox
                                  style={{ color: "#ff5722" }}
                                  checked={
                                    values.aminities.indexOf(item._id) > -1
                                  }
                                />
                                {item?.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>
                        {touched.aminities && errors.aminities}
                      </FormHelperText>
                    </FormControl>
                  </div>

                  <div className="col-md-4 mb-4">
                    <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">Special Instructions*</InputLabel>
                    <FormControl
                      style={{ width: "100%" }}
                      error={Boolean(errors.rules && touched.rules)}
                    >
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        variant="outlined"
                        displayEmpty
                        className="turf-amenity-select"
                        value={values.rules}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return (
                              <span style={{ color: "#d1d0d1" }}>
                                Choose the special instructions
                              </span>
                            );
                          }
                          let data = rule.reduce((acc, cur) => {
                            if (selected.includes(cur._id)) {
                              acc.push(cur.name)
                            }
                            return acc
                          }, [])
                          return data.join()
                          // return SelectedRules(selected);
                        }}
                        MenuProps={MenuProps}
                        {...getFieldProps("rules")}
                        helperText={touched.rules && errors.rules}
                      >
                        {rule &&
                          rule.length > 0 &&
                          rule?.map((item, index) => {
                            if (item.name) {
                              return (
                                <MenuItem key={index} value={item?._id}>
                                  <Checkbox
                                    style={{ color: "#ff5722" }}
                                    checked={
                                      values.rules.indexOf(item._id) > -1
                                    }
                                  />
                                  {item?.name}
                                </MenuItem>
                              );
                            }
                          })}
                      </Select>
                      <FormHelperText>
                        {touched.rules && errors.rules}
                      </FormHelperText>
                    </FormControl>
                  </div>

                  <div className="mb-3">
                    <h6 className="vendor-h6 d-flex">Business Hours
                      <div className="d-flex align-items-center w-50 ms-3">
                        <label htmlFor="fullDaySlot" >24 Hrs</label>
                        <input type="checkbox" id="fullDaySlot" className='ms-2' style={{ width: "15px", height: "17px" }}
                          checked={isFullDaySlot}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setIsFullDaySlot(true)
                              setFieldValue("open_time", '00:00')
                              setFieldValue("closing_time", '23:59')
                            } else {
                              setIsFullDaySlot(false)
                            }
                          }}
                        />
                      </div>
                    </h6>
                  </div>

                  <div className="col-md-2 mb-4">
                    <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">From*</InputLabel>
                    <TextField
                      type="time"
                      fullWidth

                      className="turf-vendor1"
                      name="open_time"
                      id="outlined-basic"
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={values.open_time}
                      disabled={isFullDaySlot}
                      onChange={(e) => checkUnderSpotTiming(e)}
                      // {...getFieldProps("open_time")}
                      helperText={touched.open_time && errors.open_time}
                      error={Boolean(errors.open_time && touched.open_time)}
                    />
                  </div>

                  <div className="col-md-2 mb-4">
                    <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">To*</InputLabel>
                    <TextField
                      type="time"
                      fullWidth
                      className="turf-vendor1"
                      name="closing_time"
                      id="outlined-basic"
                      disabled={isFullDaySlot}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                      value={values.closing_time}
                      onChange={(e) => checkUnderSpotTiming(e)}
                      helperText={touched.closing_time && errors.closing_time}
                      error={Boolean(
                        errors.closing_time && touched.closing_time
                      )}
                    />
                  </div>

                  <div className="col-md-4 mb-4">
                    <InputLabel
                      htmlFor="my-input"
                      className="vendor-input-label mb-1 ms-1"
                    >
                      Slot Duration
                    </InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={slotValue1}
                      fullWidth
                      // displayEmpty
                      className="slot-select"
                      // size="small"
                      // style={{ minWidth: "100px", height: "35px" }}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={handleChangeSlot}
                    >
                      <MenuItem value={30}>30 Minutes</MenuItem>
                      <MenuItem value={60}>60 Minutes</MenuItem>
                      <MenuItem value={90}>90 Minutes</MenuItem>
                      <MenuItem value={120}>2 Hours</MenuItem>
                      <MenuItem value={180}>3 Hours</MenuItem>
                      <MenuItem value={240}>4 Hours</MenuItem>
                      <MenuItem value={300}>5 Hours</MenuItem>
                      <MenuItem value={360}>6 Hours</MenuItem>
                    </Select>
                  </div>

                  <div className="col-md-4 mb-4">
                    <InputLabel
                      htmlFor="my-input"
                      className="vendor-input-label mb-1 ms-1"
                    >
                      Slot Price*
                    </InputLabel>
                    <TextField
                      type="number"
                      name="amount"
                      fullWidth
                      value={values?.amount}
                      placeholder="Slot price"
                      onChange={handleAmount}
                      // onChange={(e) => setSlotAmount(e.target.value)}
                      // {...getFieldProps("amount")}
                      helperText={touched.amount && errors.amount}
                      error={Boolean(errors.amount && touched.amount)}
                    />
                  </div>

                  <div className="col-md-12">
                    <InputLabel htmlFor="my-input" className="vendor-input-label mb-1 ms-1">Description*</InputLabel>
                    <TextField
                      type="text"
                      fullWidth
                      className="turf-description"
                      name="description"
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Description"
                      multiline
                      rows={3}
                      value={values?.description}
                      {...getFieldProps("description")}
                      helperText={touched.description && errors.description}
                      error={Boolean(errors.description && touched.description)}
                    />
                  </div>
                  {/* {
                    touched.description && errors.description ? <p style={{color:"#FF3D57" ,
                    margin: "5px",
                    textAlign: "start",
                    fontSize: "12px"
                  }}>{errors.description}</p> :""
                  } */}


                </div>
              </div>
            </div>

            <Grid item lg={6} md={3} sm={12} xs={12} sx={{ mt: 3, mb: 2 }}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                className="p-2"
                style={{
                  backgroundColor: "#333",
                  color: "white",
                  width: "10%",
                }}
              >
                <Span sx={{ textTransform: "capitalize" }}>{(loader && <Spinner size='sm' />) || "Save"}</Span>
              </Button>
            </Grid>
          </form>
        </div>
      </div>
    </div>
  )
}
