import {
  MdOutlineDashboard,
  MdOutlineAnalytics,
  MdPayment,
} from "react-icons/md";
import {
  RiFileShield2Line,
  RiFileEditLine,
  RiAdvertisementLine,
  RiTeamFill,
} from "react-icons/ri";
import { BsCalendarPlus, BsFileText, BsPostcard } from "react-icons/bs";
import { GiDiamondTrophy } from "react-icons/gi";
import {
  FaMoneyCheckAlt,
  FaRegAddressCard,
  FaRegUser,
  FaRegUserCircle,
} from "react-icons/fa";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import { TbReceiptTax } from "react-icons/tb";
import { FaQuestion } from "react-icons/fa6";


export const navigations = [
  {
    name: "Dashboard",
    path: "/dashboard/default",
    icon: <MdOutlineDashboard />,
  },
  {
    name: "Social Analytics",
    path: "/analytics",
    icon: <MdOutlineAnalytics />,
  },
  { name: "User", path: "/user-list", icon: <FaRegUser /> },
  {
    name: "Vendor",
    icon: <FaRegUserCircle />,
    children: [
      { name: "Vendor List", path: "/vendor-list", icon: <FaRegUserCircle /> },
      { name: "Vendor Payouts", path: "/vendor-payout", icon: <MdPayment /> },
      {
        name: "Settlement",
        path: "/settlements",
        icon: <FaMoneyCheckDollar />,
      },
    ],
  },
  {
    name: "Spots",
    icon: <FaRegUserCircle />,
    children: [
      { name: "Turf / Ground", path: "/turflist", icon: <BsPostcard /> },
      {
        name: "Amenities",
        path: "/amenity/amenity-list",
        icon: <RiFileShield2Line />,
      },
      { name: "Rules", path: "/rules/rules-list", icon: <BsFileText /> },
    ],
  },

  { name: "Bookings", path: "/booking-list-admin", icon: <BsCalendarPlus /> },

  {
    name: "Tournaments",
    icon: <GiDiamondTrophy />,
    children: [
      {
        name: "My Tournaments",
        path: "/tournament/my-tournaments",
        icon: <GiDiamondTrophy />,
      },
      {
        name: "User Tournaments",
        path: "/tournament/user-tournaments",
        icon: <GiDiamondTrophy />,
      },
      {
        name: "Vendor Tournaments",
        path: "/tournament/vendor-tournaments",
        icon: <GiDiamondTrophy />,
      },
      { name: "Scorer", path: "/scorer/list", icon: <FaRegAddressCard /> },
      { name: "Manager", path: "/manager/list", icon: <FaRegAddressCard /> },
    ],
  },

  { name: "Teams", path: "/teams", icon: <RiTeamFill /> },
  { name: "Inquiry", path: "/inquiry", icon: <FaQuestion /> },
  { name: "Promo Code", path: "/promocode/promocode-list", icon: "redeem" },
  { name: "Tax and Charges", path: "/tax-and-charges", icon: <TbReceiptTax /> },
  { name: "Reports", path: "/report-list", icon: <RiFileEditLine /> },
  {
    name: "Blogs",
    path: "/blog-list",
    icon: <i className="fa-solid fa-blog"></i>,
  },
  // {
  //   name: "Subscriptions",
  //   path: "/subscription-list",
  //   icon: <FaMoneyCheckAlt />,
  // },
  // { name: "Ads", path: "/add/ads", icon: <RiAdvertisementLine /> },
  // { name: "Banner", path: "/banner-list", icon: <BsPostcard /> },
];
