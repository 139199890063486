import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "app/auth/authRoles";

const Announcement = Loadable(lazy(() => import("./Announcement")));

const notificationRoutes = [
  {
    path: "/announcement",
    element: <Announcement />,
    auth: authRoles.guest,
  },
];

export default notificationRoutes;
