import { createSlice } from "@reduxjs/toolkit"

export const ProfileData = createSlice({
	name: 'Profile',
	initialState: {
		data: "",
	},
	reducers: {
		setProfileData: (state, action) => {
			state.data = action.payload
		},
		IncreaseTurfCount: (state) => {
			state.data.turf_count = Number(state.data.turf_count) + 1
		},

		// setSignupOtp: (state, action) => {
		// 	state.signupOtp = action.payload
		// },
	},

})

// Action creators are generated for each case reducer function
export const { setProfileData, IncreaseTurfCount } = ProfileData.actions

export default ProfileData.reducer