import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';


const BookingList = Loadable(lazy(() => import("./BookingList")));




const BookingRoutes = [
    {
        path: "/booking-list-admin",
        element: <BookingList />,
    },
];

export default BookingRoutes;