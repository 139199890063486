import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../Assets/css/custom.css";
import "../style.css";
import AuthRoute from "./AuthRoute";
import ListnerProtocols from "./ListnerProtocols";

const App = () => {
  const location = useLocation();

  if (location.pathname !== "/tournament/tournament-detail") {
    document.title = "CrickSage";
  }
  return (
    <>
      {/* <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider> */}
      <AuthRoute />
      <ListnerProtocols />
      {/* </AuthProvider>
          <ToastContainer/>
        </MatxTheme>
      </SettingsProvider>
    </Provider> */}
    </>
  );
};

export default App;
