import { _delete,_putAuth,_patch, _get, _post,_postAuth, convertObjToQueryStr } from "api/Apimethod"
import constant from "constant"



export const getTournamentList =  async (params) =>{
	let url = constant.Admin.tournament.tournamentList
	let str = ''
	if(params){
		str = convertObjToQueryStr(params)
	}
    url = url + str
 const response = await _get(url);
 return response
}

export const getTournamentListManager =  async (params) =>{
	let url = constant.Admin.tournament.tournamentListManager
    let str = convertObjToQueryStr(params)
    url = url + str
 const response = await _get(url);
 return response
}
export const getTournamentListScorer =  async (params) =>{
	let url = constant.Admin.tournament.tournamentListScorer
    let str = convertObjToQueryStr(params)
    url = url + str
 const response = await _get(url);
 return response
}

export const getToolTip =  async (id) =>{
	let url = constant.Admin.tournament.toolTipData + 'tournament_id=' + id
 const response = await _get(url);
 return response
}


export const getSingleTournamentAbout = async (id) => {
	const url =  constant.Admin.tournament.tournamentAbout + id; 
	const response = await _get(url)
	return response
  }

export const addTournament = async (data) =>{
let url = constant.Admin.tournament.addTournament
const response = await _postAuth(url, data)
return response
}

export const updateTournament = async (data , id) => {
	let url = constant.Admin.tournament.editTournament+"/"+id
	const response = await _putAuth(url,data)
	return response;
  };

export const addSponsors = async (data) =>{
	let url = constant.Admin.tournament.addSponsors
	const response = await _postAuth(url, data)
	return response
}

export const sponsorsList = async (id) =>{
	let url = constant.Admin.tournament.sponsorsList + id
	const response = await _get(url)
	return response
}

export const deleteSponsor = async(id) =>{
	let url = constant.Admin.tournament.deleteSponsor + id
	const response = await _delete(url)
	return response
}

export const deleteTournament = async(id) =>{
	let url = constant.Admin.tournament.deteleTournament +"/"+ id
	const response = await _delete(url)
	return response
}

export const getTurfData = async(params) =>{
	let url = constant.Admin.TurfList
    let str = convertObjToQueryStr(params)
    url = url + str
 const response = await _get(url);
 return response
}

// {{local}}/api/team/team_tournament?tournament_id=64772477634208b7b27af4ff


export const getTeamList = async (params) => {
	let url =  constant.Admin.tournament.teamList
	let str = convertObjToQueryStr(params)
	url = url+str
	const response = await _get(url)
	return response
  }	
export const getPlayingXIList = async (params) => {
	let url =  constant.Admin.tournament.playingXI
	let str = convertObjToQueryStr(params)
	url = url + str
	const response = await _get(url)
	return response
  }
export const removePlayer = async (params) => {
	let url =  constant.Admin.tournament.removePlayer
	let str = convertObjToQueryStr(params)
	url = url + str
	const response = await _delete(url)
	return response
}
export const addPlayer = async (params) => {
	let url =  constant.Admin.tournament.addPlayer
	const response = await _putAuth(url,params)
	return response
}
export const updateTeam = async (id,params) => {
	let url =  constant.Admin.tournament.updateTeam+id
	const response = await _putAuth(url,params)
	return response
}

export const getFixtureTeamList = async (id) => {
	let url =  constant.Admin.tournament.fixtureTeamList+id
	// let str = convertObjToQueryStr(params)
	// url = url + str
	const response = await _get(url)
	return response
  }


export const AddTeamAdmin = async (data) =>{
	const url = constant.Admin.tournament.addTeamAdmin
	const response = await _postAuth(url, data)
	return response
}

// fixture services-----------------------------------------------

export const getMatchList = async(params) =>{
	let url = constant.Admin.tournament.Matchlist
	let str = ''
	if(params){
		str = convertObjToQueryStr(params)
	}
    url = url + str
 const response = await _get(url);
 return response
}
export const getManagerMatchList = async(params) =>{
	let url = constant.Admin.tournament.MnagerMatchList
	let str = convertObjToQueryStr(params)
    url = url + str
 const response = await _get(url);
 return response
}

export const getTournamentGround = async(params) =>{
	let url = constant.Admin.tournament.tournamentGround
	let str = convertObjToQueryStr(params)
    url = url + str
 const response = await _get(url);
 return response
}

export const fixTeamMatch = async (data) =>{
	const url = constant.Admin.tournament.fixMatch
	const response = await _post(url, data)
	return response
}
export const PatchPlayingXI = async (data) =>{
	const url = constant.Admin.tournament.AddplayingXI
	const response = await _patch(url, data)
	return response
}



// tournament Groups 
export const getGroups = async() =>{
	let url = constant.Admin.tournament.getGroups
	const response = await _get(url);
	return response
}

export const CreateGroup = async(data) =>{
	let url = constant.Admin.tournament.manageGroup
 const response = await _post(url, data);
 return response
}

export const updateGroup = async(data) =>{
	let url = constant.Admin.tournament.updateGroup
 const response = await _patch(url, data);
 return response
}

export const getGroupWiseTeam = async(params) =>{
	let url = constant.Admin.tournament.manageGroup
	let str = convertObjToQueryStr(params)
    url = url + str
 const response = await _get(url);
 return response
}

export const deleteGroup = async(id) =>{
	let url = constant.Admin.tournament.manageGroup +'/'+id
 const response = await _delete(url);
 return response
}


// Scorer

export const createScorer= async(data) =>{
	let url = constant.Admin.tournament.createScorer
 const response = await _postAuth(url, data);
 return response
}

export const createManager= async(data) =>{
	let url = constant.Admin.tournament.createManager
 const response = await _postAuth(url, data);
 return response
}

export const getScorerList = async(params) =>{
	let url = constant.Admin.tournament.getScorerList
	let str = params && convertObjToQueryStr(params)
	if(params){
		url = url + str
	}
	const response = await _get(url);
	return response
}

export const changeScorerFixture = async(params) =>{
	let url = constant.Admin.tournament.changeScorerFixture
	const response = await _postAuth(url,params);
	return response
}


export const getManagerList = async(params) =>{
	let url = constant.Admin.tournament.getManagerList
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _get(url);
	return response
}

export const getSingleScorer = async(id) =>{
	let url = constant.Admin.tournament.getSingleScorer + id
	const response = await _get(url);
	return response
}

export const getSingleManager = async(id) =>{
	let url = constant.Admin.tournament.getSingleManager + id
	const response = await _get(url);
	return response
}

export const updateScorer = async (data , id) => {
	let url = constant.Admin.tournament.updateScorer+"/"+id
	const response = await _putAuth(url,data)
	return response;
  };
export const updateManager = async (data , id) => {
	let url = constant.Admin.tournament.updateManager+"/"+id
	const response = await _putAuth(url,data)
	return response;
  };

  export const deleteScorer = async(id) =>{
	let url = constant.Admin.tournament.deleteScorer + id
	const response = await _delete(url)
	return response
}
  export const deleteManager = async(id) =>{
	let url = constant.Admin.tournament.deleteManager + id
	const response = await _delete(url)
	return response
}

export const getTournament = async() =>{
	let url = constant.Admin.tournament.tournamentList
	const response = await _get(url);
	return response
}

export const getnewTournamentlist = async() =>{
	let url = constant.Admin.tournament.newtournamentList
	const response = await _get(url);
	return response
}

export const scorerAsign = async(params) =>{
	let url = constant.Admin.tournament.asignRole 
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _postAuth(url);
	return response
}

export const scorerUnassign = async(params) =>{
	let url = constant.Admin.tournament.unAssignScorer 
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _putAuth(url);
	return response
}

export const managerAsign = async(params) =>{
	let url = constant.Admin.tournament.asignManager
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _postAuth(url);
	return response
}

export const getMatcheslist = async(params) =>{
	let url = constant.Admin.tournament.getMatches
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _get(url);
	return response
}

export const getTopBatsman = async(params) =>{
	let url = constant.Admin.tournament.topBatsman
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _get(url);
	return response
}

export const getTopBowler = async(params) =>{
	let url = constant.Admin.tournament.topBowler
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _get(url);
	return response
}

export const getTopMvp = async(params) =>{
	let url = constant.Admin.tournament.topMVP
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _get(url);
	return response
}

export const getTopFielder = async(params) =>{
	let url = constant.Admin.tournament.topFielder
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _get(url);
	return response
}

export const getOverAllStats = async(params) =>{
	let url = constant.Admin.tournament.topStats
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _get(url);
	return response
}

export const getMatchListScorer = async(params) =>{
	let url = constant.Admin.tournament.matchlistscorer
	let str = ""
	if(params){
		str = convertObjToQueryStr(params)
	}
    url = url + str
	const response = await _get(url);
	return response
}

export const getManagerScorer = async() =>{
	let url = constant.Admin.tournament.managerScorer
	// let str = convertObjToQueryStr(params)
    // url = url + str
	const response = await _get(url);
	return response
}

export const getTurfs = async(params) =>{
	let url = constant.Admin.turfs.turfList
	let str = convertObjToQueryStr(params)
    url = url + str
	const response = await _get(url);
	return response
}


// vendor tournament admin and scorer 

export const getVendorTournamentAdminsList = async(id) =>{
	let url = constant.Vendor.tournament.tournamentAdminList + "/" + id
	const response = await _get(url);
	return response
}

export const getVendorTournamentScorerList = async(id) =>{
	let url = constant.Vendor.tournament.scorerList + "/" + id
	const response = await _get(url);
	return response
}

export const assignTournamentAdmin = async(params) =>{
	let url = constant.Vendor.tournament.assignTournamentAdmin
	const response = await _postAuth(url,params);
	return response
}
export const assignTournamentScorer = async(params) =>{
	let url = constant.Vendor.tournament.assignScorer
	const response = await _postAuth(url,params);
	return response
}

export const removeTournamentAdmin = async (data) => {
	let url = constant.Vendor.tournament.removeTournamentAdmin
	const response = await _putAuth(url,data)
	return response;
};
export const removeTournamentScorer = async (data) => {
	let url = constant.Vendor.tournament.removeScorer
	const response = await _putAuth(url,data)
	return response;
};

export const getTournamentStats = async(params) =>{
	let url = constant.Vendor.tournament.tournamentStats
	if(params){
		let str = convertObjToQueryStr(params)
    	url = url + str
	}
	const response = await _get(url);
	return response
}

export const deleteTournamentFixture = async(id) =>{
	let url = constant.Admin.tournament.deleteFixture + "/" + id
	const response = await _delete(url)
	return response
}