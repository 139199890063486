import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';






const Bookings = Loadable(lazy(() => import("./Bookings")));
const BookingInvoice = Loadable(lazy(() => import("./BookingInvoice")));
const BookingTab = Loadable(lazy(() => import("./BookingTab")));
const AvailableAmendSlots = Loadable(lazy(() => import( "./AmendSlots/AvailableAmendSlots")));
const PaymentMethod = Loadable(lazy(() => import( "./PaymentMethod")));
const AvailableSlots = Loadable(lazy(() => import( "./ExtendSlots/AvailableSlots")));



const reviewsRoutes = [
    {
        path: "/vendor-bookings",
        element: <Bookings />,
        auth: authRoles.guest,
    },
    // {
    //     path: "/vendor-bookings-tab/:id",
    //     element: <BookingTab />,
    //     auth: authRoles.guest,
    // },
    {
        path: "/vendor-bookings/invoice/:id",
        element: <BookingInvoice />,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-bookings/vendor-bookings-tab/:id",
        element: <BookingTab />,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-amend-bookings",
        element: <AvailableAmendSlots />,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-bookings/vendor-available-slots",
        element: <AvailableSlots />,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-bookings/vendor-amend-slots",
        element: <AvailableAmendSlots />,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-payment",
        element: <PaymentMethod />,
        auth: authRoles.guest,
    },



  
];

export default reviewsRoutes;