import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';


const SettlementList = Loadable(lazy(() => import("./SettlementList")));


const settlementRoutes = [
    {
        path: "/settlements",
        element: <SettlementList />,
        auth: authRoles.guest,
    }

];

export default settlementRoutes;