
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';


const HomePage = Loadable(lazy(() => import('./HomePage')));



const homeRoutes = [
  { path: '/', element: <HomePage/>},
]
export default homeRoutes;
