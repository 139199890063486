import React from 'react';
import useSettings from 'app/hooks/useSettings';
import { VendorLayouts } from '.';
import { MatxSuspense } from 'app/components';

const VendorLayout = (props) => {
    const { settings } = useSettings();
    const TopLAyout = VendorLayouts[settings.activeLayout];

    return (
        <MatxSuspense>
          <TopLAyout {...props} />
        </MatxSuspense>
      );

}

export default VendorLayout;