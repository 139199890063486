import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';
import { Navigate } from 'react-router-dom';
import PlayerDetail from './child/PlayerDetail';


const DashboardTab = Loadable(lazy(() => import('./DashboardTab')));
// const OverAll = Loadable(lazy(() => import('./child/OverAll')));
// const SportAnalytics = Loadable(lazy(() => import('./child/SportAnalytics')));
// const General = Loadable(lazy(() => import('./child/General')));
const TopUsers = Loadable(lazy(() => import('./child/overAllComponent/TopUsers')));
const TopVendors = Loadable(lazy(() => import('./child/overAllComponent/TopVendors')));

const dashboardRoutes = [
  { path: '/dashboard/:tab', element: <DashboardTab />, auth:authRoles.admin },
  // { path: '/dashboard/default/general', element: <General />, auth:authRoles.guest },
  // { path: '/dashboard/default/social-media', element: <SocialMedia />, auth:authRoles.guest },
  { path: '/all-users', element: <TopUsers />, auth:authRoles.guest },
  { path: '/all-vendors', element: <TopVendors />, auth:authRoles.guest },
  { path: '/player-detail/:id', element: <PlayerDetail />, auth:authRoles.guest },
  { path: '/*', element:  <Navigate to="/dashboard/default"/>, auth:authRoles.admin },

];

export default dashboardRoutes;
