import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from '../../../auth/authRoles';


const TaxCharges = Loadable(lazy(() => import('./TaxCharges.js')));

const groundsRoutes = [
  { path: '/tax-and-charges', element: <TaxCharges />, auth:authRoles.admin },
//   { path: '/teams/details/:id', element: <TeamDetail />, auth:authRoles.admin },

];

export default groundsRoutes;