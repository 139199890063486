import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';





const Revenuepage = Loadable(lazy(() => import("./RevenueList")));




const revenueRoutes = [
	{
		path: "/revenue-list",
		element: <Revenuepage />,
		auth: authRoles.guest,
	},




];

export default revenueRoutes;