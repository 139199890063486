
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';


const VenodorPage = Loadable(lazy(() => import('./VenodorPage')));



const venodorRoutes = [
  { path: '/vendor', element: <VenodorPage />},
];

export default venodorRoutes;
