import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import { Navigate } from 'react-router-dom';


const TounamentListScorer = Loadable(lazy(() => import('./TounamentListScorer')));
const MatchTab = Loadable(lazy(() => import('./StartMatch/MatchTab')));



const ScorerRoutes = [
  {
      path: "/tournament",
      element: <TounamentListScorer />,
      auth: authRoles.guest,
  },

  {
      path: "/match/:id",
      element: <MatchTab />,
      auth: authRoles.guest,
  },
  { path: '/*', element:  <Navigate to="/tournament"/>, auth:authRoles.guest },
]

export default ScorerRoutes;