import { Helper } from "app/helperFuntion";
import DLSSheet from "./dlsJson.json"

export const scoreFunctions = {

  getOrdinalNumber : (i) => {
      let j = i % 10;
      let k = i % 100;
      if (j === 1 && k !== 11) {
          return i + "st";
      }
      if (j === 2 && k !== 12) {
          return i + "nd";
      }
      if (j === 3 && k !== 13) {
          return i + "rd";
      }
      return i + "th";
  },

  getApproxOvers: (overs) => {
    return (
      (String(overs).split(".")[1] === "6" &&
        Number(String(overs).split(".")[0]) + 1) ||
      overs
    );
  },

  getBallFromOver: (ov) => {
    let over = ov.toFixed(1).split(".");
    return over[0] * 6 + Number(over[1]);
  },

  checkInningsNumber : (runningMatchData=null,battingTeam=null) => {
    let isTrue = false
    if(runningMatchData && battingTeam && Object.keys(runningMatchData).length > 0){
      if(runningMatchData.innings[0].first_bat){
        if(runningMatchData.innings[1].batted_team_id === battingTeam.team_id){
            isTrue = true
        }
      }else{
        if(runningMatchData.innings[0].batted_team_id === battingTeam.team_id){
          isTrue = true
        }
      }
    }else if(runningMatchData && !battingTeam && Object.keys(runningMatchData).length > 0){
      if((runningMatchData.innings[0].first_bat && runningMatchData.innings[1].batsmen.length>0) ||
      (runningMatchData.innings[1].first_bat && runningMatchData.innings[0].batsmen.length>0)){
        isTrue = true
      }
    }
    return isTrue
  },

  getOverPercent :  (ov) => {
    if(ov && String(ov).includes(".")){
      let over = ov.toFixed(1).split(".");
      let remPer = String((over[1] * 100) / 6).includes(".") 
                  ? String((over[1] * 100) / 6).split(".").join("").slice(0,4) 
                  : String((over[1] * 100) / 6)
      return Number(`${over[0]}.${remPer}`)
    }
    return ov
  },

  getProjectedScore : (runningMatchData) => {
    if(runningMatchData && runningMatchData?.innings){
      if((runningMatchData.innings[0].first_bat && runningMatchData.innings[1].batsmen.length>0) ||
      (runningMatchData.innings[1].first_bat && runningMatchData.innings[0].batsmen.length>0) ){
        return "0"
      }else if(runningMatchData.innings[0].first_bat){
        return Math.round((runningMatchData.innings[0]?.run_rate || 0) * scoreFunctions.getOverPercent(runningMatchData.match_settings.no_of_overs))
      }else if(runningMatchData.innings[1].first_bat){
        return Math.round((runningMatchData.innings[1]?.run_rate || 0) * scoreFunctions.getOverPercent(runningMatchData.match_settings.no_of_overs))
      }else{
        return "0"
      }
    }else{
      return "0"
    }
  },

  checkIsMatchDrawAndRunning : (runningMatchData) => {
    let firstBat = runningMatchData.innings[0].first_bat ? runningMatchData.innings[0] : runningMatchData.innings[1]
    let secondBat = runningMatchData.innings[0].first_bat ? runningMatchData.innings[1] : runningMatchData.innings[0]
    let targetScore = runningMatchData?.dls?.target ? (Number(runningMatchData.dls.target)-1) : firstBat.runs_scored
    if(runningMatchData && runningMatchData?.outcome?.is_super_over && runningMatchData?.super_over && runningMatchData?.super_over.length>0){
      let lastSuperOver = runningMatchData?.super_over[runningMatchData?.super_over.length-1]
      if(lastSuperOver?.outcome?.is_super_over){
        if(Number(lastSuperOver.innings[0].runs_scored) === Number(lastSuperOver.innings[1].runs_scored)){
          return true
        }
      }else if(Number(lastSuperOver.innings[0].runs_scored) === Number(lastSuperOver.innings[1].runs_scored)){
        return true
      }else{
        return false
      }
    }else if(runningMatchData?.outcome && !runningMatchData.outcome.is_super_over){
      return false
    }else if(Number(targetScore) === Number(secondBat.runs_scored)){
      return true
    }
    return false
  },


  checkIsMatchCompleted : (runningMatchData) => {
    if(runningMatchData && runningMatchData?.super_over && runningMatchData.super_over.length>0){
      if(runningMatchData.super_over[runningMatchData.super_over.length-1]?.outcome){
        if(!runningMatchData.super_over[runningMatchData.super_over.length-1].outcome.is_super_over){
          return true
        }
      }
    }else if(runningMatchData && runningMatchData?.outcome && !runningMatchData?.outcome?.is_super_over){
      return true
    }
    return false
  },

  getSuperOverNumber : (matchData) => {
    if(matchData?.super_over && matchData.super_over.length>1){
      return scoreFunctions.getOrdinalNumber(matchData.super_over.length)
    }
    return false
  },

  getTotalOvers : (runningMatchData) => {
    if(runningMatchData?.super_over && runningMatchData.super_over.length>0){
      return  1
    }else if(runningMatchData?.dls){
      if(runningMatchData.dls?.innings && runningMatchData.dls.innings.length>1 ){
        return runningMatchData.dls.innings[1]?.reduced_over || runningMatchData.dls.innings[1]?.inning_start_over
      }else{
        return runningMatchData.dls.innings[0]?.reduced_over || runningMatchData.dls.innings[0]?.inning_start_over
      }
    }
    return runningMatchData.match_settings.no_of_overs
  },


  // get final outcome
  getOutCome1: (runningMatchData) => {
    let outcome = "";
    let curMatch = runningMatchData;
    if (Object.keys(runningMatchData).length > 0) {
      if(runningMatchData?.super_over && runningMatchData.super_over.length>0){
        curMatch = { 
          ...runningMatchData.super_over[runningMatchData.super_over.length-1],
          team1 : runningMatchData.team1,
          team2 : runningMatchData.team2,
        }
      }
      let first_bat_team =
      (curMatch.innings[0].first_bat &&
        curMatch.innings[0]) ||
        curMatch.innings[1];
        let second_bat_team =
        (curMatch.innings[0].first_bat &&
          curMatch.innings[1]) ||
          curMatch.innings[0];
      let targetScore = ((runningMatchData?.super_over && runningMatchData.super_over.length>0) || !runningMatchData?.dls?.target) ? first_bat_team.runs_scored : runningMatchData.dls.target-1
      let wicket_win =
        (curMatch.innings[0].first_bat &&
          curMatch.team2.players.length -
            (curMatch.innings[1].batsmen.length - 1)) ||
            curMatch.team1.players.length -
          (curMatch.innings[0].batsmen.length - 1);
        
      if(second_bat_team.batsmen.length===0){
        outcome = "No Result"
      }else  if (
        Number(second_bat_team.runs_scored) < Number(targetScore)
      ) {
        outcome = `${first_bat_team.batting_team} won by ${
          Number(targetScore) -
          Number(second_bat_team.runs_scored) 
        } Runs ${runningMatchData?.dls?.target ? `( DLS target ${runningMatchData.dls.target} )` : ""}`;
      } else if (
        Number(second_bat_team.runs_scored) > Number(targetScore)
      ) {
        outcome = `${second_bat_team.batting_team} won by ${wicket_win} Wicket ${runningMatchData?.dls?.target ? `( DLS target ${runningMatchData.dls.target} )` : ""}`;
      } else if (
        Number(second_bat_team.runs_scored) ===
        Number(targetScore)
      ) {
        outcome = `Match Draw ${runningMatchData?.dls?.target ? `( DLS target ${runningMatchData.dls.target} )` : ""}`;
      }
    }
    return outcome;
  },

  // outcome include runs needed in balls 
  getOutCome: (updatedData) => {
    let outcome = "";
    let curMatch = updatedData;
    if(updatedData?.super_over && updatedData.super_over.length>0){
      curMatch = { 
        ...updatedData.super_over[updatedData.super_over.length-1],
        team1 : updatedData.team1,
        team2 : updatedData.team2,
      }
    }
    let first_bat_inning = (curMatch.innings[0].first_bat && curMatch.innings[0]) || curMatch.innings[1];
    let second_bat_inning = (curMatch.innings[0].first_bat && curMatch.innings[1]) || curMatch.innings[0];

    let targetScore = ((updatedData?.super_over && updatedData.super_over.length>0) || !updatedData?.dls?.target) ? first_bat_inning.runs_scored : (Number(updatedData.dls.target)-1)
    let dlsIsTarget = curMatch?.match_id ? "" : updatedData?.dls?.target ? `( DLS target ${updatedData.dls.target} )` : ""
    let second_bat_team = (curMatch.innings[0].first_bat && curMatch.team2) || curMatch.team1;
    if (curMatch && Object.keys(curMatch).length > 0) {
      if (curMatch?.outcome) {
        if (!curMatch.outcome.winner
        ) {
          if(curMatch.outcome?.by?.result === "DRAW"){
            outcome =  "Match Draw";
          }else if(curMatch.outcome?.by?.result === "NORESULT"){
            outcome =  "Not Result";
          }else{
            outcome = curMatch.outcome?.by?.result
          }
        } else{
          if(curMatch?.match_id){
            let winnerTeamName = curMatch.team1.team_id===curMatch?.outcome.winner ? curMatch.team1.team_name : curMatch.team2.team_name
            outcome = `Match Tied ${winnerTeamName} won the ${scoreFunctions.getSuperOverNumber(updatedData) || ''} Super over`;
          } else if(curMatch.innings[0].first_bat){
            if(curMatch.innings[0].batted_team_id === curMatch?.outcome.winner){
              outcome = `${curMatch.innings[0].batting_team} won by ${curMatch?.outcome?.by?.runs} Runs ${dlsIsTarget}`;
            }else{
              outcome = `${curMatch.innings[1].batting_team} won by ${curMatch?.outcome?.by?.wickets} Wicket ${dlsIsTarget}`; 
            }
        }else {
          if(curMatch.innings[1].batted_team_id === curMatch?.outcome.winner){
            outcome = `${curMatch.innings[1].batting_team} won by ${curMatch?.outcome?.by?.runs} Runs ${dlsIsTarget}`;
          }else{
            outcome = `${curMatch.innings[0].batting_team} won by ${curMatch?.outcome?.by?.wickets} Wicket ${dlsIsTarget}`; 
          }
        }
      }
      } else if (
        (Number(targetScore) <
        Number(second_bat_inning.runs_scored)) ||
        (scoreFunctions.getApproxOvers(second_bat_inning.overs_played) ===
          Number(scoreFunctions.getTotalOvers(updatedData))) ||
        (Number(second_bat_inning.wickets_lost) ===
          Number(second_bat_team.players.length - (curMatch.match_settings?.last_men ? 0 : 1)))
          || (curMatch?.match_id && second_bat_inning.wickets_lost===2)
      ) {
        outcome = scoreFunctions.getOutCome1(curMatch);
      } else if (
        curMatch?.innings &&
        curMatch?.innings[0].first_bat &&
        curMatch?.innings[1].batsmen.length > 0
      ) {
        outcome = `${curMatch?.innings[1].batting_team} need ${
          Number(targetScore) -
          Number(curMatch.innings[1].runs_scored) +
          1
        } runs in ${
          scoreFunctions.getBallFromOver(
            scoreFunctions.getTotalOvers(updatedData)
          ) -
          scoreFunctions.getBallFromOver(curMatch.innings[1].overs_played)
        } ball to Win ${dlsIsTarget}`;
      } else if (
        curMatch?.innings &&
        curMatch?.innings[1].first_bat &&
        curMatch?.innings[0].batsmen.length > 0
      ) {
        outcome = `${curMatch?.innings[0].batting_team} need ${
          Number(targetScore) -
          Number(curMatch.innings[0].runs_scored) +
          1
        } runs in ${
          scoreFunctions.getBallFromOver(
            scoreFunctions.getTotalOvers(updatedData)
          ) -
          scoreFunctions.getBallFromOver(curMatch.innings[0].overs_played)
        } ball to Win ${dlsIsTarget}`;
      } else if (curMatch?.toss && curMatch.toss.won_by === curMatch.team1.team_id) {
        outcome = `${curMatch.team1.team_name} won the Toss and elected to ${
          (curMatch?.toss.choose === "BAT" && "Bat") || "Ball"
        } first`;
      } else {
        if(curMatch?.toss){
          outcome = `${curMatch.team2.team_name} won the Toss and elected to ${
            (curMatch?.toss.choose === "BAT" && "Bat") || "Ball"
          } first`;
        }else{
          outcome = 'Super over'
        }
      }
    } else {
      outcome = "Not Yet Started";
    }
    return outcome;
  },


  overLeft: (targetOver, completeOver) => {
    const [intPart, decimalPart] = completeOver.toString().split(".");
    var decimalFraction = parseFloat(`0.${decimalPart}`);
    if(targetOver == completeOver){
      return 0
    }else{
      if (decimalFraction !== -0) {
        decimalFraction = (0.6 - decimalFraction).toFixed(1);
        const remainingOvers = targetOver - parseInt(intPart) - 1 + decimalFraction;
        return remainingOvers;
      } else {
        const remainingOvers = targetOver - parseInt(intPart);
        return remainingOvers;
      }
    }
  },
  
  getAdjustedTableType : (over) => {
    let isMidOver = over ? (over - parseInt(over) != 0) ? true : false : false
    if (isMidOver) {
      if (40 <= over && over <= 50) return "fiftyToForty";
      else if (30 <= over && over <= 40) return "fortyToThirty";
      else if (20 <= over && over <= 30) return "thirtyToTwenty";
      else if (10 <= over && over <= 20) return "twentyToTen";
      else if (0 <= over && over <= 10) return "tenToZero";
    } else {
      return "fiftyToZero";
    }
  },


  getDLS: (params,match) => {
    let G50 = 245;
    let paramsData = params?.inning2 ? params?.inning2 : params?.inning1
    if(paramsData){
      let firstInningScore = match.innings[0].first_bat ? match.innings[0].runs_scored : match.innings[1].runs_scored
        let updatedData = {
            delays: {
              current_over: paramsData.current_over,
              wicket_lost: paramsData.wicket_lost,
              runs: paramsData.runs,
            },
            inning_start_over: paramsData?.inning_start_over || match.match_setting.no_of_overs,
            resources: DLSSheet['fiftyToZero'][paramsData?.inning_start_over || match.match_setting.no_of_overs][0],
            reduced_over: paramsData?.reduced_over,
            power_play: paramsData?.power_play || '',
            over_per_bowler: paramsData?.over_per_bowler || "",
        }
        let dlsSetData = {}
        if(match?.dls){
          dlsSetData = JSON.parse(JSON.stringify(match.dls))
        }else{
          dlsSetData = { innings : [] }
        }
        if(paramsData?.current_over && paramsData?.reduced_over && paramsData.current_over!==0 && paramsData.reduced_over!==0){
            let overrallRemOver = scoreFunctions.overLeft(Number(updatedData.inning_start_over) , Number(paramsData.current_over))
          let overalltable = scoreFunctions.getAdjustedTableType(overrallRemOver)
            let overrallRemOverPer = DLSSheet[overalltable][overrallRemOver][paramsData.wicket_lost]
            let currentRemOver = scoreFunctions.overLeft(Number(paramsData.reduced_over) , Number(paramsData.current_over))
          let currenttable = scoreFunctions.getAdjustedTableType(currentRemOver)
            let currentRemOverPer = DLSSheet[currenttable][currentRemOver][paramsData.wicket_lost]
            let diffOfPer = overrallRemOverPer - currentRemOverPer
            updatedData.resources = updatedData.resources - diffOfPer
        } 

        if(!dlsSetData.innings[0]){
          dlsSetData.innings[0] = { resources: DLSSheet["fiftyToZero"][match.match_setting.no_of_overs][0] }
        }
        dlsSetData.innings[params?.inning1 ? 0 : 1] = updatedData
        if(params?.inning2){
            if(dlsSetData.innings[1].resources < dlsSetData.innings[0].resources){
                let diff = dlsSetData.innings[1].resources / dlsSetData.innings[0].resources
                dlsSetData.target = Math.floor(firstInningScore * diff + 1)
            }
            if(dlsSetData.innings[1].resources === dlsSetData.innings[0].resources){
              dlsSetData.target = firstInningScore + 1
            }
            if(dlsSetData.innings[1].resources > dlsSetData.innings[0].resources){
                let diff = dlsSetData.innings[1].resources - dlsSetData.innings[0].resources
                dlsSetData.target = Math.floor(firstInningScore + ((diff * G50)/100) + 1)
            }
        }
        return dlsSetData
    }
  },

  getPlayerMVPPoints : (runningMatchData,mom) => {
    let mvp = [...runningMatchData?.bowler, ...runningMatchData?.batsman];
    let arr = [];
    mvp.map(e => {
      let ff = arr.findIndex(el => {
        return el.players_id === e.players_id;
      });
      if (ff > -1) {
        arr[ff].mvp_points = Helper.numberWithDecimal(arr[ff].mvp_points + e.mvp_points,2);
      } else {
        arr.push(e);
      }
    });
    mvp = arr
      .sort((a, b) => {
        return b.mvp_points - Helper.numberWithDecimal(a.mvp_points,2);
      })
      .map(e => {
        let teamName = '';
        if (
          runningMatchData?.team1?.players?.find((ej) => {
            return ej.players_id === e.players_id;
          })
        ) {
          teamName = runningMatchData?.team1?.team_name;
        }
        if (
          runningMatchData?.team2?.players?.find((ej) => {
            return ej.players_id === e.players_id;
          })
        ) {
          teamName = runningMatchData?.team2?.team_name;
        }
        return {
          ...e,
          total_mvp: Helper.numberWithDecimal(e.mvp_points,2),
          team_name: teamName,
        };
      });
      if(mom){
        if(runningMatchData?.outcome?.winner && runningMatchData?.outcome?.by?.result && runningMatchData.outcome.by.result==='RESULT'){
          return mvp.find((item)=> item.team_id===runningMatchData.outcome.winner)
        }
        return mvp[0]
      }
      return mvp;
  },

};
