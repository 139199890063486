
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';




const WebProfile = Loadable(lazy(() => import('./WebProfile')));                                                                                             



const webProfileRoutes = [
  { path: '/web-profile', element: <WebProfile/>},

]
export default webProfileRoutes;
