import useAuth from './hooks/useAuth'
import routes from './routes'
import { useRoutes } from 'react-router-dom';

const AuthRoute = () => {
    const { isAuthenticated, role } = useAuth()
    let mainroute = []
    if (isAuthenticated && role) {
        const route = routes[role]
        mainroute = [...route]
    } else {
        const route = routes.common
        const route1 = routes.nonHeader
        const route2 = routes.nonFooter
        mainroute = [...route, ...route1, ...route2]
    }

    const content = useRoutes(mainroute)
    return content
}

export default AuthRoute