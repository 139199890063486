import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';





const RulesList = Loadable(lazy(() => import("./RulesList")));
const EditRules = Loadable(lazy(() => import("./EditRules")));
const AddRules = Loadable(lazy(() => import("./AddRules")));




const rulesRoutes = [
    {
        path: "/rules/rules-list",
        element: <RulesList />,
        auth: authRoles.guest,
    },
    {
        path: "/rules/edit/:id",
        element: <EditRules/>,
        auth: authRoles.guest,
    },
    {
        path: "/rules/add",
        element: <AddRules />,
        auth: authRoles.guest,
    },
    

];

export default rulesRoutes;