import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';

const VendorList = Loadable(lazy(() => import("./VendorList")))
const EditVendor = Loadable(lazy(() => import("./EditVendor")))
const VendorProfile = Loadable(lazy(() => import("./VendorProfile")))
const VendorDetail = Loadable(lazy(() => import("./VendorDetail")))
const VendorPayout = Loadable(lazy(() => import("./Vendor Payment/VendorPayout")))
const TurfDetail = Loadable(lazy(() => import("./TurfDetail")))



const vendorRoutes = [
    {
        path: "/vendor-list",
        element: <VendorList />,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-list/edit-vendor/:id",
        element: <EditVendor />,
        auth: authRoles.guest,
    },

    {
        path: "/vendor-list/vendor-profile/:id",
        element: <VendorProfile />,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-list/vendor-detail/:id",
        element: <VendorDetail />,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-list/turf-detail/:id",
        element: <TurfDetail />,
        auth: authRoles.guest,
    },
    {
        path: "/vendor-payout",
        element: <VendorPayout />,
        auth: authRoles.guest,
    },






];

export default vendorRoutes;