import { createSlice } from "@reduxjs/toolkit"

export const ExtendAndAmend = createSlice({
	name: 'Data',
	initialState: {
		data: '',
	},
	reducers: {
		setExtendData: (state, action) => {
			state.data = action.payload
		},

		// setSignupOtp: (state, action) => {
		// 	state.signupOtp = action.payload
		// },
	},

})

// Action creators are generated for each case reducer function
export const { setExtendData } = ExtendAndAmend.actions

export default ExtendAndAmend.reducer