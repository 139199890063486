
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';



const ContactUs = Loadable(lazy(() => import('./ContactUs')));                                                                                             



const contactRoutes = [
  { path: '/contact-us', element: <ContactUs/>},
]
export default contactRoutes;
