import { CircularProgress } from '@mui/material';
import { Box, styled } from '@mui/system';
// import { loading } from 'Assets';
import Lottie from 'react-lottie';
import animationlogo from '../components/cricketLoader'

const StyledLoading = styled('div')(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '& img': {
    width: 'auto',
    height: '39px',
  },

  // '& img': {
  //   width: 'auto',
  //   height: '25px',
  // },

  '& .circleProgress': {
    position: 'absolute',
    left: 0,
    // width: '100px',
    // height: '100px',
    right: 0,
    // top: 'calc(50% - 25px)',
  },
}));

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationlogo,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const Loading = () => {
  return (
    <StyledLoading>
      <Box position="relative">
      <Lottie 
	    options={defaultOptions}
        height={100}
        width={100}
      />
        {/* <img src={'/assets/images/logos/crickbook.png'} alt="" />
        <CircularProgress className="circleProgress" /> */}
      </Box>
    </StyledLoading>
  );
};

export default Loading;
