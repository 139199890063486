
import Loadable from 'app/components/Loadable';
import { lazy } from 'react';


const GroundPage = Loadable(lazy(() => import('./GroundPage')));
const Gallery = Loadable(lazy(() => import('./child/Gallery')));
const AddTurf = Loadable(lazy(() => import('./child/Addturf')))



const groundRoutes = [
  { path: '/ground/:id', element: <GroundPage /> },
  { path: '/ground/:id/gallery/:id', element: <Gallery /> },
  {
    path: "/ground/:id/add-turf",
    element: <AddTurf />,
  },


]
export default groundRoutes;
