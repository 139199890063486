import Loadable from "app/components/Loadable";
import { lazy } from "react";


const ScorerList = Loadable(lazy(() => import("./ScorerList")));
const AddScorer = Loadable(lazy(() => import("./AddScorer")));



const scorerRoute = [
    {
        path: "/scorer/list",
        element: <ScorerList />,
    },
    {
        path: "/scorer/add",
        element: <AddScorer />,
    },
];

export default scorerRoute;