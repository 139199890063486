import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from '../../../auth/authRoles';


const InquiryList = Loadable(lazy(() => import("./InquiryList")));


const InquiryRoute = [
    {
        path: "/inquiry",
        element: <InquiryList />,
    },
];

export default InquiryRoute;