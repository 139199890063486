import Loadable from "app/components/Loadable";
import { lazy } from "react";
import { authRoles } from "../../../auth/authRoles";

const TurfList = Loadable(lazy(() => import("./TurfList")));
const TurfDetail = Loadable(lazy(() => import("../Vendormanagement/TurfDetail")));

const groundsRoutes = [
  { path: "/turflist", element: <TurfList />, auth: authRoles.admin },
  {
    path: "/turflist/turf-detail/:id",
    element: <TurfDetail />,
    auth: authRoles.guest,
  },
  //   { path: '/teams/details/:id', element: <TeamDetail />, auth:authRoles.admin },
];

export default groundsRoutes;
